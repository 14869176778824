import classNames from "classnames";
import React from "react";
import { Plus } from "react-feather";
import styled from "styled-components";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import theme from "../../theme";
import * as actions from "../../store/actions";
import texts from "../../texts";
import { useNavigate } from "@reach/router";
import { useLoading } from "../../hooks/useLoading";

function WelcomeContent() {
  const t = useI18n();
  const user = useAppState((s) => s.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [creating, load] = useLoading();
  const handleCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    load(
      dispatch(
        actions.projects.createProject({
          title: t(texts.newReport),
        })
      ).then((p) => {
        navigate(`/projects/${p.id}/clips`);
      })
    );
  };
  return (
    <div className="py-5">
      <p className="text-center mb-2">
        👋{" "}
        {t({
          en: "Welcome to Reach Report",
          fr: "Bienvenue sur Reach Report",
        })}{" "}
        {user?.name}
      </p>
      <h1 className="rr-dark-grey text-center mb-4">
        {t({
          en: "Let’s create a report!",
          fr: "Créez un rapport !",
        })}
      </h1>
      <div className="row mx-3">
        <div className="col-12 col-md-4 text-center mb-4">
          <div className="text-center mb-3">
            <Bubble>1</Bubble>
          </div>
          <p className="rr-t2">
            {t({
              en: "Add URLs to all the articles & posts you want to analyze.",
              fr: "Ajoutez les URLs et couvertures que vous souhaitez analyser dans votre rapport.",
            })}
          </p>
        </div>
        <div className="col-12 col-md-4 text-center">
          <div className="text-center mb-3">
            <Bubble>2</Bubble>
          </div>
          <p className="rr-t2">
            {t({
              en: "ReachReport will make clips and add relevant metrics.",
              fr: "Nous récupèrerons automatiquement des images et les métriques les plus importantes.",
            })}
          </p>
        </div>
        <div className="col-12 col-md-4 text-center">
          <div className="text-center mb-3">
            <Bubble>3</Bubble>
          </div>
          <p className="rr-t2">
            {t({
              en: "Review and share your coverage report!",
              fr: "Quand vous avez fini d'éditer votre rapport, customisez votre design et partagez le résultat !",
            })}
          </p>
        </div>
      </div>
      <div className="text-center mt-3">
        <button
          className="btn btn-primary"
          onClick={handleCreate}
          disabled={creating}
        >
          <Plus />{" "}
          {t({
            en: "Create new report",
            fr: "Créez un nouveau rapport",
          })}
        </button>
      </div>
    </div>
  );
}

export default function WelcomePopup() {
  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal show d-block" tabIndex={-1} role="dialog">
        <ModalDialog>
          <div className="modal-content">
            <div className="modal-body">
              <WelcomeContent />
            </div>
          </div>
        </ModalDialog>
      </div>
    </>
  );
}

const Bubble = styled.div.attrs((p) => ({
  ...p,
  className: classNames(p.className, "rr-t1"),
}))`
  width: 48px;
  height: 48px;
  display: inline-flex;
  vertical-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${theme.lightestBlue};
  border-radius: 100%;
  color: ${theme.lightBlue};
`;

const ModalDialog = styled.div.attrs((p) => ({
  ...p,
  className:
    "modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable",
  role: "document",
}))`
  @media (max-width: 767px) {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  @media (min-width: 768px) {
    min-width: 766px;
  }
`;
