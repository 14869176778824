import * as React from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { Card } from "../components/CardPage";
import useI18n from "../hooks/useI18n";

export function Terms(_props: RouteComponentProps) {
  const t = useI18n();
  return (
    <>
      <p className="lead mt-5 text-center">
        <Link to="/">⟵ {t({ en: "Home", fr: "Accueil" })}</Link>
      </p>
      <Card>
        <h1>
          ReachReport.io
          <br />
          Terms of service
        </h1>

        <p>Last revised on January 4, 2023</p>

        <p>
          ReachReport provides the site and its related services ("Services"),
          in accordance with the terms and conditions ("Terms of Service")
          outlined below.
        </p>

        <p>
          Please read these carefully before proceeding to use the services
          provided. ReachReport reserves the right to update and edit the Terms
          of service without prior notice.
        </p>

        <p>
          New features that may be added to the Services will be subject to the
          Terms of service. Use of the Service after any of these modifications
          have been implemented will constitute your implicit acceptance of said
          modifications. You can consult the most recent version of the Terms of
          service at https://reachreport.io/terms.
        </p>

        <p>
          Should You breach any of the Terms of service, your account will be
          terminated.
        </p>

        <h2>1. Account terms</h2>

        <p>You must be over the age of sixteen (16) to access this Service.</p>

        <p>
          You must provide a full legal name, an email address, and any other
          required information to complete the registration process.
        </p>

        <p>
          You are responsible for maintaining the privacy of your account.
          ReachReport will not be held responsible for any damage or loss
          resulting from failure to protect your login information, including
          your password.
        </p>

        <p>
          One person and / or company may not have more than one free account.
        </p>

        <h2>2. Payment and access</h2>

        <p>
          A valid credit card is required to subscribe to the Service. A credit
          card is not required to create a free account.
        </p>

        <p>
          ReachReport will charge You a monthly fee based on your account type.
          The Service is charged a month in advance and is non-refundable. There
          will be no refunds or credits for partial months of service, nor
          refunds should You not use the Service during a period of time while
          having an active subscription. No exceptions will be made.
        </p>

        <p>
          Should You change your type of account, your credit card will be
          charged the new billing amount immediately. Going forward, your credit
          card will be charged the new billing rate every 30 days unless You
          cancel your account.
        </p>

        <p>
          All fees are exclusive of all taxes or duties imposed by governing
          authorities. You are responsible for paying all such taxes or duties.
        </p>

        <h2>3. Modifications to the Service and Fees</h2>

        <p>
          ReachReport reserves the right to temporarily, or permanently, edit or
          suspend the Service for any reason at any time with or without notice.
        </p>

        <p>
          ReachReport reserves the right to change their fees with 30 days
          notice. Any changes in fees will be published on the website
          ReachReport
        </p>

        <h2>4. Cancellation and termination</h2>

        <p>
          You have sole responsibility for the proper cancellation of your
          account. You may cancel your account at any time by clicking on the
          corresponding link in the navigation menu of the Service.The
          Subscription section provides a straightforward link to cancel your
          account. Email or phone requests to cancel your account will not be
          deemed cancellation.
        </p>

        <p>
          Your account and all of its Content will be deleted immediately upon
          your cancellation.
        </p>

        <p>
          You may cancel at any time, but You will remain liable for all charges
          accrued up to that time, including usage charges for the month in
          which You canceled the service. Going forward, You will not be charged
          again.
        </p>

        <p>
          ReachReport reserves the right to temporarily or permanently edit,
          suspend, or terminate your account for any reason at any time without
          notice or refund. ReachReport also reserves the right to delete all
          Content associated with your account. ReachReport may refuse Service
          to any entity for any reason at any time.
        </p>

        <h2>5. Copyright and Intellectual Ownership</h2>

        <p>
          ReachReport, its affiliates or its suppliers own the Intellectual
          Property rights to any and all protectable components of the Service,
          including but not limited to the name of the Service, all software
          associated with the Service, graphic material, many of the individual
          features, and the related documentation.
        </p>

        <p>
          You may not copy, edit, adapt, reproduce, distribute or reverse
          engineer any aspect of the Service that ReachReport, its affiliates or
          its suppliers own. You also agree to not use robots, spiders or
          automated devices to control or copy any content of the Service.
        </p>

        <p>
          ReachReport claims no Intellectual Property rights over the Content
          You upload or provide to the Service. However, by using the Service to
          share Content, You agree that others may view and share your Content.
        </p>

        <p>
          Certain Services may facilitate the aggregation, display, storage and
          other uses of third party proprietary materials (such as APIs, data,
          ratings, text, images, video content and other online content). You
          shall take sole responsibility for your proper and lawful usage of
          these third party materials, including identifying and obtaining any
          requisite third party licences at your sole cost and expense. You
          agree to comply with the reasonable directions of ReachReport.io
          and/or relevant third parties regarding the usage of third party
          intellectual property.
        </p>

        <h2>6. Privacy and Data Protection</h2>

        <p>
          When You enter or upload your data on our Services, You grant us a
          licence to use, copy, transmit, store, analyse, and back up all data
          You submit to us through our Services, including personal data of
          yourself and others, to:
        </p>

        <ul>
          <li>Enable You to use our Services</li>
          <li>Allow us to improve, develop and protect our Services</li>
          <li>Create new Services</li>
          <li>Communicate with You about your subscription</li>
          <li>Send You information we think may be of interest to you</li>
        </ul>

        <p>
          Please refer to Our Privacy Policy for details on Our privacy
          practices through the use of ReachReport. By using ReachReport, You
          acknowledge that We may collect information which is considered as
          being personal information and/or personal information which is
          defined as being sensitive, under applicable laws. You acknowledge
          that You shall be solely and exclusively responsible to provide all
          the necessary controls on Your website as well as obtain any consent,
          which You might be legally obliged to obtain from Your customers.
        </p>

        <p>
          We do not process "sensitive personal data" under the GDPR, including
          data revealing:
        </p>

        <ul>
          <li>Racial or ethnic origin</li>
          <li>Political opinions</li>
          <li>Religious or philosophical beliefs</li>
          <li>Trade union membership</li>
          <li>Genetic data</li>
          <li>Biometric data</li>
          <li>
            Data concerning health or a natural person’s sex life and/or sexual
            orientation
          </li>
        </ul>

        <p>
          By accepting the terms contained within this Agreement, You
          acknowledge represent an d warrant that You will not store any of this
          type of information in ReachReport and You shall comply with all
          applicable laws, including but not limited to data protection and
          privacy laws and that You shall indemnify ReachReport Indemnified
          Parties against any Third Party claims related to violation of such
          applicable laws in the use of the Service.
        </p>

        <p>
          Privacy Policy on Your website: We shall not be held responsible or
          liable upon failure to do so. Failure to implement such privacy policy
          terms on Your website might result in the termination of Your Account.
          Our Privacy Policy is hereby incorporated by reference into and made
          part of this Agreement.
        </p>

        <h2>7. Confidential Information</h2>

        <p>
          While using our services, You may share confidential information with
          us, and You may become aware of confidential information about us. We
          both agree to take reasonable steps to protect the other party’s
          confidential information from being accessed by unauthorised
          individuals. If required to do so You or Us may share each other’s
          confidential information with legal or regulatory authorities.
        </p>

        <h2>8. Disclaimer and Warning</h2>

        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>

        <p>
          ReachReport its subsidiaries, affiliates, and its licensors do not
          warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </p>

        <p>
          We may immediately suspend your use of the Website, Platform,
          Configurations and/or Services if we are contacted by your targeted
          data extraction source, website, or repository and asked to cease all
          extraction activity. If such an event occurs, we will not disclose
          your information without a court order mandating us to do so unless we
          in our best judgment determine that there would be an adverse
          consequence if we do not. If, however, we receive a court order
          demanding the release of your information to a third party, we will
          comply. If such action becomes necessary, you agree to indemnify and
          hold us and (as applicable) our parent(s), subsidiaries, affiliates,
          officers, directors, agents, contractors and employees, harmless from
          any claim or demand, including reasonable attorneys' fees, made by any
          third party arising from any complaint, suit, disagreement or other
          repercussions resulting from your use of the Website, Platform,
          Configurations or Services.
        </p>

        <p>
          Should any third party claim its rights against us in connection to
          your actions, we may immediately eliminate any contents gathered,
          saved or disseminated by you from servers used by us. In the event of
          a judicial dispute with a third party related to your actions, you are
          obliged to provide us with all necessary cooperation in order to
          resolve such a dispute successfully and you are also obliged to
          reimburse continuously any purposeful expenses arising to us due to
          such a dispute. With respect to this, should an obligation arise to
          reimburse any claim of a third party, you agree to pay us the full
          scope of the damages.
        </p>

        <h2>9. General Conditions</h2>

        <p>
          You understand and agree that the Service is provided "as is" and "as
          available". ReachReport declines all liability for the availability,
          security, opportunity or reliability of the Service. You assume total
          responsibility and risk for your use of this Service.
        </p>

        <p>
          You agree not to resell, duplicate, exploit or reproduce any part of
          the Service without the express written permission of ReachReport. You
          may not use the Service to store, host, or send damaging or
          unsolicited email (spam).
        </p>

        <p>
          You understand that the Service can be used for transmission of your
          Content, and that during processing, your Content, including clips,
          coverage reports, and messages, may be transferred unencrypted over
          the Internet.
        </p>

        <p>
          You may not use the service to transmit any viruses, worms, or any
          type of malicious or damaging content.
        </p>

        <p>
          ReachReport makes no guarantees regarding (a) your ability to use the
          Service, (b) your satisfaction with the Service, (c) that the Service
          will be available at all times, uninterrupted, and error-free, (d) the
          accuracy of mathematical calculations performed by the Service, and
          (d) that errors in the Service will be corrected.
        </p>

        <p>
          Neither ReachReport, its affiliates or sponsors are responsible for
          any direct, indirect, incidental, consequential, special, exemplary,
          punitive or other damages arising out of or relating in any way to
          your use of the Service. Your sole remedy for dissatisfaction with the
          Service is to stop using the Service.
        </p>

        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by ReachReport. ReachReport has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that ReachReport shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services.
        </p>

        <p>
          By using our API clients, you also agree to be bound by the Terms of
          3rd party products that are used in the Services, such as{" "}
          <a
            href="https://www.youtube.com/t/terms"
            target="_blank"
            rel="noreferrer"
          >
            YouTube's Terms of Service
          </a>
          .
        </p>

        <p>
          We strongly advise you to read the YouTube terms & conditions and
          privacy policies, as well that of any other third-party web sites or
          services that you visit or are used in the Services.
        </p>

        <p>
          We are not obliged to verify the manner in which you or other users
          use the Website, Platform, Configuration or Services and we shall not
          be liable for the manner of such usage. We assume that you use the
          Website Platform and Services legally and ethically and that you have
          obtained permission, if necessary, to use it on the targeted websites
          and/or other data sources.
        </p>

        <p>
          If any provision of the terms described here is held invalid or
          otherwise unenforceable, the enforceability of the remaining
          provisions shall not be impaired thereby.
        </p>

        <p>
          You agree to protect and fully compensate ReachReport and its
          affiliates, representatives and employees from any and all third party
          claims arising from your use of the Service, including responsibility
          or expense incurred through claims, losses, any kind of damages,
          litigations, resolutions, legal costs and lawyers fees. In such a
          case, ReachReport will notify You in writing of said claim, litigation
          or action.
        </p>

        <p>
          Any questions regarding the Terms of service should be addressed to
          the ReachReport support team: support@reachreport.io.
        </p>

        <p>
          The failure of ReachReport to exercise any right provided for in these
          Terms of service shall not be deemed a waiver of any right hereunder.
        </p>

        <p>
          The Terms of service set forth the entire understanding between You
          and ReachReport as to the Service and supersedes any prior agreements
          between You and ReachReport (including, but not limited to, prior
          versions of the Terms of service).
        </p>

        <p>
          The Terms, as well as your relation to ReachReport in accordance with
          the said Terms, will be bound by Dutch Law. You and ReachReport agree
          to be subject to the exclusive jurisdiction of the Dutch Courts in
          order to resolve any legal issue concerning the Terms. Without
          prejudice to the above, You agree to ReachReport's right to request
          the adoption of precautionary measures (or any other equivalent urgent
          measures) under any jurisdiction.
        </p>
      </Card>
    </>
  );
}
