import { useDispatch } from "./useAppState";
import { useLoading } from "./useLoading";
import * as actions from "../store/actions";
import React from "react";

/**
 * Loads specified project in app state.
 * @return loading state.
 */
export const useProject = (id: string): [boolean] => {
  const [loading, load] = useLoading();
  const dispatch = useDispatch();
  React.useEffect(() => {
    load(dispatch(actions.projects.loadProject(id)));
  }, [id, load, dispatch]);
  return [loading];
};
