import { Link } from "@reach/router";
import React from "react";

type CommonProps = {
  children: any;
  action?: { label: any; link: string };
};
type Props =
  | (CommonProps & { danger: true })
  | (CommonProps & { info: true })
  | (CommonProps & { success: true });

export default function Alert(props: Props) {
  const theme =
    "danger" in props
      ? "alert-danger"
      : "info" in props
      ? "alert-info"
      : "success" in props
      ? "alert-success"
      : "";
  return (
    <div
      className={
        "alert rr-t4 mb-0 px-4 py-4 d-flex justify-content-between align-items-center " +
        theme
      }
    >
      <div className="mr-2">{props.children}</div>
      {props.action && (
        <Link className="btn btn-primary btn-sm px-3" to={props.action.link}>
          {props.action.label}
        </Link>
      )}
    </div>
  );
}
