export function getAuthUrl(redirect: string, state: string) {
  const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");
  url.searchParams.append("response_type", "code");
  url.searchParams.append(
    "client_id",
    process.env.REACT_APP_GOOGLE_CLIENT_ID || ""
  );
  url.searchParams.append("redirect_uri", redirect);
  url.searchParams.append(
    "scope",
    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
  );
  url.searchParams.append("state", state);
  return url;
}
