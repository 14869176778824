import styled from "styled-components";
import theme from "../../theme";

const BoxImage = styled.div<{ src: string; width?: string; height?: string }>`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${theme.grey};
  background-image: url("${(props) => props.src}");
  width: ${(props) => props.width || "100%"};
  ${(props) => props.height && "height: " + props.height}
`;

export default BoxImage;
