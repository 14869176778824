import { useNavigate } from "@reach/router";
import React from "react";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import * as actions from "../../store/actions";
import appTexts from "../../texts";
import { getWorkspaceName } from "../../model";
import useDropdownSystem from "../../hooks/useDropdownSystem";

export default function CreateReportDropdown({ close }: { close: () => void }) {
  const t = useI18n();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaces = useAppState((s) => s.workspaces);
  const workspace = useAppState((s) => s.workspace);
  const dropdown = useDropdownSystem(close);
  return (
    <div
      className="dropdown-menu dropdown-menu-right show"
      ref={dropdown}
      onClick={(e) => e.stopPropagation()}
      style={{
        zIndex: 2000,
      }}
    >
      <form
        className="p-3"
        onSubmit={(e) => {
          e.preventDefault();
          const wid = e.currentTarget.workspace_id.value;
          if (wid === "") {
            dispatch(
              actions.workspaces.createWorkspace({
                name: t(appTexts.newWorkspace),
              })
            ).then((w) => {
              dispatch(
                actions.projects.createProject({
                  title: t(appTexts.newProject),
                  workspace_id: w.id,
                })
              ).then((p) => {
                navigate(`/projects/${p.id}/clips`);
              });
            });
          } else {
            dispatch(
              actions.projects.createProject({
                title: t(appTexts.newProject),
                workspace_id: wid,
              })
            ).then((p) => {
              navigate(`/projects/${p.id}/clips`);
            });
          }
        }}
      >
        <div>
          <label>
            {t({
              en: "Select workspace",
              fr: "Choisisez l'emplacement",
            })}
          </label>
          <select
            key={workspace?.id || workspaces[0]?.id || ""}
            name="workspace_id"
            className="custom-select mb-3"
            defaultValue={workspace?.id || workspaces[0]?.id}
          >
            <option value="">
              {t({ en: "Create new", fr: "Nouveau Client" })}
            </option>
            {workspaces.map((w) => (
              <option key={w.id} value={w.id}>
                {getWorkspaceName(w, t)}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button className="border-0 bg-white text-primary rr-cta px-0">
            {t({ en: "Create report", fr: "Créer le rapport" })}
          </button>
        </div>
      </form>
    </div>
  );
}
