import * as React from "react";
import { PageStat } from "../store/types";
import cls from "classnames";
import { numberF } from "../utils/numberF";

const numFormat = (
  n: number,
  decimals: number = 0,
  decimalSeparator = ",",
  spacing = " "
) =>
  (n || 0)
    .toFixed(decimals) // no decimal
    .replace(".", decimalSeparator) // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + spacing); // use space

export function Stat({
  id: key,
  label,
  stat,
  onChange,
}: {
  id: string;
  label: string;
  stat: PageStat;
  onChange: (key: string, disabled: boolean, value?: number | null) => void;
}) {
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(
    (stat?.userValue || stat?.rrValue || 0).toString()
  );
  const toggleStat = () => {
    const disabled = stat && !stat?.disabled;
    onChange(
      key,
      disabled || false,
      disabled ? stat?.userValue : stat?.userValue || 0
    );
  };
  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim().toLowerCase();
    const num = parseInt(value.replace(/[^0-9]/g, ""));
    setValue(num.toString());
  };
  const onInputBlur = (e: React.FormEvent<HTMLInputElement>) => {
    setEditing(false);
    const v = parseInt(value) || 0;
    if (v === 0) {
      onChange(key, !stat?.rrValue, v);
    } else {
      onChange(key, v === 0, v);
    }
  };
  const onKeyDown = (e: React.KeyboardEvent) => {
    // enter
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      (e.target as any).blur();
    }
    // espace
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      setEditing(false);
    }
  };
  return (
    <div
      className={cls(
        "py-1 d-flex justify-content-between align-items-center hover-rr-bg-softer-grey",
        {
          "text-muted": !stat || stat.disabled,
        }
      )}
    >
      <div className="rr-t2 flex-grow-1 flex-shrink-0 rr-mid-grey">{label}</div>
      <div className="text-right rr-t2b flex-grow-1 pr-5">
        {stat && (
          <>
            {editing ? (
              <input
                type="text"
                value={numFormat(parseInt(value) || 0, 0, ".", " ")}
                onChange={onInputChange}
                onBlur={onInputBlur}
                onKeyDown={onKeyDown}
                className="form-control form-control-sm text-primary d-inline-block"
                autoFocus
                style={{
                  width: "125px",
                  marginTop: "-4px",
                  marginBottom: "-4px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
                tabIndex={1}
              />
            ) : stat.disabled ? (
              <a
                href="/"
                className="text-muted"
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(true);
                  setValue((stat?.userValue || stat?.rrValue || 0).toString());
                }}
                tabIndex={1}
              >
                {numberF(stat.userValue || stat.rrValue || 0)}
                <i className="ml-3 fa fa-edit text-muted font-weight-bold" />
              </a>
            ) : (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(true);
                  setValue((stat?.userValue || stat?.rrValue || 0).toString());
                }}
                tabIndex={1}
              >
                {numberF(stat.userValue || stat.rrValue || 0)}
                <i className="ml-3 fa fa-edit text-primary font-weight-bold" />
              </a>
            )}
          </>
        )}
      </div>
      <div className="custom-control custom-switch">
        <input
          tabIndex={1}
          type="checkbox"
          checked={stat && !stat.disabled}
          className={"custom-control-input"}
          id={`switch-${key}`}
          onChange={toggleStat}
        />
        <label className="custom-control-label" htmlFor={`switch-${key}`} />
      </div>
    </div>
  );
}
