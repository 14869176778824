import { RouteComponentProps, navigate, useLocation } from "@reach/router";
import React from "react";
import { CardPage } from "../components/CardPage";
import useI18n, { useLang } from "../hooks/useI18n";
import { api } from "../api";
import { getCookie } from "../utils/cookies";
import { useDispatch } from "../hooks/useAppState";
import * as actions from "../store/actions";

type Props = { service: string };

export function OauthCallback(props: RouteComponentProps<Props>) {
  const location = useLocation();
  const dispatch = useDispatch();
  const lang = useLang();
  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code") || "";
    const state = JSON.parse(query.get("state") || "null");
    const redirect = state?.redirect || "";
    const referer_code = getCookie("ref") || "";
    const src_referer_url = getCookie("src_referer_url") || "";
    const src_landing_url = getCookie("src_landing_url") || "";
    api
      .GoogleAuth(
        {},
        { referer_code, lang, code, redirect, src_referer_url, src_landing_url }
      )
      .then(async (res) => {
        if (res.data.newUser) {
          (window as any).gtag_report_conversion &&
            (window as any).gtag_report_conversion();
          (window as any).plausible && (window as any).plausible("Signup");
        }
        await dispatch(actions.loadSession(res.data.session.token));
      })
      .then(() => navigate("/"))
      .catch((e) => {
        console.log(e);
        navigate("/");
      });
  }, []);
  const t = useI18n();
  return (
    <CardPage
      coloredBox={t({ en: <h1>Connecting</h1>, fr: <h1>Connexion</h1> })}
      children={undefined}
    />
  );
}
