import * as React from "react";
import { useState } from "react";
import useDropdownSystem, {
  createDropdownState,
} from "../../hooks/useDropdownSystem";
import useI18n from "../../hooks/useI18n";
import { useDispatch } from "../../hooks/useAppState";
import { Feed } from "../../model";
import appTexts from "../../texts";
import { Trash, Rss } from "react-feather";
import theme from "../../theme";
import styled from "styled-components";
import * as actions from "../../store/actions";

export const useFeedDropdown = createDropdownState();

export const FeedDropdown = ({ feed }: { feed: Feed }) => {
  const t = useI18n();
  const dropdown = useFeedDropdown();
  const ref = useDropdownSystem(dropdown.close);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [option, setOption] = useState<string>("");

  const onDelete = () => {
    setOption("delete");
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(actions.feeds.deleteFeed(feed.id)).finally(() =>
        dropdown.close()
      );
    }
  };

  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        className="dropdown-menu show"
        ref={ref}
        style={{
          zIndex: 2000,
          padding: "12px 8px",
          minWidth: "256px",
          maxWidth: "320px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={onDelete}>
          <Trash width={18} className="text-danger mr-1" /> {t(appTexts.delete)}
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <p className="rr-t5 text-center">
              Note: will also delete related mentions.
            </p>
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmDelete)}
              </button>
            </div>
          </InOptionForm>
        )}
        <a
          className="dropdown-item"
          href={feed.url}
          target="_blank"
          rel="noreferrer"
        >
          <Rss width={18} className="mr-1" /> {t({ en: "Link to feed" })}
        </a>
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
