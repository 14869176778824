import * as React from "react";
import classnames from "classnames";
import { RouteComponentProps } from "@reach/router";
import { useLoggedState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import { useLoading } from "../hooks/useLoading";
import * as actions from "../store/actions";
import useI18n from "../hooks/useI18n";
import _ from "lodash";
import { Plus } from "react-feather";
import CreateReportDropdown from "../components/ui/CreateReportDropdown";
import ReportCard from "../components/ui/ReportCard";
import WorkspaceListing from "../components/ui/WorkspaceListing";
import WelcomePopup from "../components/ui/WelcomePopup";

export function Workspaces(_props: RouteComponentProps) {
  const t = useI18n();
  const workspaces = useLoggedState((s) => s.workspaces);
  const projects = useLoggedState((s) => s.projects);
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [createDropdown, setCreateDropdown] = React.useState(false);
  // Load projects on entering dashboard
  React.useEffect(() => {
    dispatch({ type: "EXIT_PROJECT" });
    dispatch({ type: "EXIT_WORKSPACE" });
    load(dispatch(actions.workspaces.loadWorkspaces()));
  }, [dispatch, load]);
  const recent = _(projects).orderBy("udate", "desc").take(4).value();
  return (
    <>
      <FullPage className="">
        {loading ? (
          <></>
        ) : (
          <>
            {projects.length === 0 && <WelcomePopup />}
            <div className="m-3 m-md-2 p-md-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="rr-t1">
                  {t({ en: "Last modified", fr: "Dernière modif." })}
                </div>
                <button
                  className="d-flex align-items-center btn btn-primary btn-sm"
                  // data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateDropdown(true);
                  }}
                >
                  <Plus height="18" className="mr-1" />
                  <div className="d-md-none">
                    {t({ en: <>New report</>, fr: <>Nouveau</> })}
                  </div>
                  <div className="d-none d-md-block">
                    {t({ en: <>New report</>, fr: <>Nouveau rapport</> })}
                  </div>
                </button>
                {createDropdown && (
                  <CreateReportDropdown
                    close={() => setCreateDropdown(false)}
                  />
                )}
              </div>
              <div className="row mt-3">
                {recent.map((r) => (
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3" key={r.id}>
                    <ReportCard report={r} key={r.id} />
                  </div>
                ))}
              </div>
              <div
                className={classnames("mt-4", {
                  "d-none": workspaces.length === 0,
                })}
              >
                {workspaces.map((p, i) => (
                  <WorkspaceListing workspace={p} key={p.id} />
                ))}
              </div>
            </div>
          </>
        )}
      </FullPage>
    </>
  );
}
