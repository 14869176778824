import _ from "lodash";
import React from "react";
import { ArrowLeft, X } from "react-feather";
import styled from "styled-components";
import AccountSettingsDrawer from "./components/drawers/AccountSettingsDrawer";
import AdminUserDrawer from "./components/drawers/AdminUserDrawer";
import CfcDrawer from "./components/drawers/CfcDrawer";
import ClipDrawer from "./components/drawers/ClipDrawer";
import DesignLibraryDrawer from "./components/drawers/DesignLibraryDrawer";
import PlansDrawer from "./components/drawers/PlansDrawer";
import ProjectDesignDrawer from "./components/drawers/ProjectDesignDrawer";
import ProjectShareDrawer from "./components/drawers/ProjectShareDrawer";
import TeamsDrawer from "./components/drawers/TeamsDrawer";
import FeedItemDrawer from "./components/drawers/FeedItemDrawer";
import { Drawer, DrawerState, useAppDrawer } from "./hooks/useAppDrawer";
import useI18n from "./hooks/useI18n";
import theme from "./theme";

export default function Drawers() {
  const stack = useAppDrawer((s: DrawerState) => s.stack);
  const close = useAppDrawer((s: DrawerState) => s.close);
  const [slides, setSlides] = React.useState(
    [] as {
      drawer: Drawer;
      status: "init" | "opening" | "closing";
      id: string;
    }[]
  );
  const [status, setStatus] = React.useState(
    "init" as "init" | "opening" | "closing"
  );

  React.useEffect(() => {
    // update slides status
    const newSlides = _.cloneDeep(slides);
    // slides popped
    newSlides.forEach((s, i) => {
      if (!stack[i] && s.status !== "closing") {
        s.status = "closing";
      }
    });
    // new slides
    stack.forEach((s: Drawer, i: number) => {
      if (!newSlides[i]) {
        newSlides[i] = {
          drawer: s,
          status: "opening",
          id: i + "--" + JSON.stringify(s),
        };
      }
    });
    setSlides(newSlides);
    // update overlay status depending on stack length
    if (status !== "opening" && stack.length > 0) {
      setStatus("opening");
    } else if (status !== "closing" && stack.length === 0) {
      setStatus("closing");
    }
  }, [stack]);

  const popSlide = (id: string) => {
    setSlides(slides.filter((s) => s.id !== id));
  };

  useStopScroll(slides.length > 0);
  return slides.length > 0 ? (
    <>
      <Overlay
        onClick={close}
        className={status}
        onScroll={(e) => {
          // e.preventDefault();
          // e.stopPropagation();
        }}
        onScrollCapture={(e) => {
          // e.preventDefault();
          // e.stopPropagation();
        }}
      ></Overlay>
      {slides.map((slide, i) => (
        <OneDrawer
          key={slide.id}
          id={slide.id}
          index={i}
          drawer={slide.drawer}
          status={slide.status}
          pop={popSlide}
        />
      ))}
    </>
  ) : null;
}

function DrawersContent({ drawer }: { drawer: Drawer }) {
  if (drawer === "settings") {
    return <AccountSettingsDrawer />;
  } else if (drawer === "subscriptions") {
    return <PlansDrawer />;
  } else if (drawer === "teams") {
    return <TeamsDrawer />;
  } else if (drawer === "design_library") {
    return <DesignLibraryDrawer />;
  } else if (drawer === "cfc") {
    return <CfcDrawer />;
  } else if ("project_share" in drawer) {
    return <ProjectShareDrawer id={drawer.project_share} />;
  } else if ("project_design" in drawer) {
    return (
      <ProjectDesignDrawer id={drawer.project_design} focus={drawer.focus} />
    );
  } else if ("clip_edit" in drawer) {
    return <ClipDrawer id={drawer.clip_edit} />;
  } else if ("admin_user" in drawer) {
    return <AdminUserDrawer id={drawer.admin_user} />;
  } else if ("feed_item" in drawer) {
    return <FeedItemDrawer id={drawer.feed_item} />;
  }
  return (
    <>
      <p>Unkwown drawer</p>
    </>
  );
}

let scrollY = 0;

function useStopScroll(stop = false) {
  React.useEffect(() => {
    if (stop) {
      scrollY = window.scrollY;
      document.body.style.overflow = "hidden";
      document.body.style.width = "100%";
      document.body.style.position = "fixed";
      document.body.style.overflowY = "scroll !important";
      document.body.style.top = `-${scrollY}px`;
    } else {
      document.body.style.removeProperty("overflow");
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, scrollY);
    }
  }, [stop]);
}

function OneDrawer({
  id,
  index,
  drawer,
  status,
  pop,
}: {
  id: string;
  index: number;
  drawer: Drawer;
  status: "init" | "opening" | "closing";
  pop: (id: string) => void;
}) {
  const t = useI18n();
  const close = useAppDrawer((s: DrawerState) => s.close);
  const [displayStatus, setDisplayStatus] = React.useState("init");
  React.useEffect(() => {
    if (displayStatus !== "opening" && status === "opening") {
      setTimeout(() => setDisplayStatus("opening"), 50);
    } else if (displayStatus !== "closing" && status === "closing") {
      setDisplayStatus("closing");
      setTimeout(() => pop(id), 250);
    }
  }, [status, pop, id, displayStatus]);
  React.useEffect(() => {
    const escFn = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };
    document.addEventListener("keydown", escFn);
    return () => {
      document.removeEventListener("keydown", escFn);
    };
  }, []);
  return (
    <SlidePanel className={displayStatus} over={index > 0}>
      <div onClick={close} className="py-2 d-flex align-items-center">
        <button
          className="btn bg-white text-primary rr-cta pl-4"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            close();
          }}
        >
          {index === 0 ? (
            <>
              <X /> {t({ en: "Close", fr: "Fermer" })}
            </>
          ) : (
            <>
              <ArrowLeft />
              Previous
            </>
          )}
        </button>
      </div>
      <DrawersContent drawer={drawer} />
    </SlidePanel>
  );
}

const Overlay = styled.div`
  position: fixed;
  z-index: 1080;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: opacity 125ms ease-in-out;
  &.opening {
    opacity: 1;
  }
  &.closing {
    opacity: 0;
  }
`;

const SlidePanel = styled.div<{ over?: boolean }>`
  position: fixed;
  z-index: 1080;
  transition: all 250ms ease-in-out;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  @media (min-width: 640px) {
    width: 95%;
  }
  max-width: 486px;
  right: -110%;
  background-color: ${theme.white};
  box-shadow: ${(props) =>
    props.over
      ? "0px 0px 10px rgba(0, 0, 0, 0.3)"
      : "0px 0px 10px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.3)"};
  border-radius: 0px !important;

  display: flex;
  flex-direction: column;

  &.opening {
    right: 0vw;
  }
  &.closing {
    right: -110%;
  }
`;
