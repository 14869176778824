import loadImage from "blueimp-load-image";

/** Resize the file  */
export const imgResize = async (
  file: File,
  args?: {
    maxHeight?: number;
    maxWidth?: number;
  }
): Promise<File> => {
  args = args || {};
  args.maxHeight = args.maxHeight || 1280;
  args.maxWidth = args.maxWidth || 1280;
  const res = await loadImage(file, {
    canvas: true,
    maxHeight: args.maxHeight,
    maxWidth: args.maxWidth,
  });
  const canvas = (res.image as any) as HTMLCanvasElement;
  const blob: Blob = await new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Unable to convert blob"))),
      "image/png"
    );
  });
  return new File([blob], file.name.replace(/(\.[a-zA-Z0-9]+)$/, ".png"), {
    type: "image/png",
  });
};

export default imgResize;
