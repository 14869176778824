import { createPopper, Instance } from "@popperjs/core";
import React from "react";
import zustand from "zustand";

export interface DrowdownState {
  id: string | null;
  context: string | null;
  select: (id: string | null, context?: string | null) => void;
  isSelected: (id: string | null, context?: string | null) => boolean;
  close: () => void;
}

export function createDropdownState() {
  return zustand<DrowdownState>((set, get) => ({
    id: null,
    context: null,
    select: (id: string | null, context: string | null = null) => {
      set({ id, context });
    },
    isSelected: (id: string | null, context: string | null = null) => {
      const { id: currentId, context: currentContext } = get();
      return currentId === id && currentContext === context;
    },
    close: () => set({ id: null, context: null }),
  }));
}

export default function useDropdownSystem(
  close: () => void,
  popperConfig: any = {
    placement: "bottom-end",
    strategy: "fixed",
  }
) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    let pop: Instance | null = null;
    try {
      if (ref.current != null && (ref.current as any).parentElement) {
        pop = createPopper(
          (ref.current as any).parentElement,
          ref.current,
          popperConfig as any
        );
      }
    } catch (e) {}
    const click = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      close();
    };
    window.addEventListener("click", click);
    return () => {
      pop && pop.destroy();
      pop = null;
      window.removeEventListener("click", click);
    };
  }, [close, popperConfig]);
  return ref;
}
