import _ from "lodash";
import React from "react";
import { FilePlus, Trash, Archive } from "react-feather";
import styled from "styled-components";
import zustand from "zustand";
import { useDispatch, useLoggedState } from "../../hooks/useAppState";
import useDropdownSystem from "../../hooks/useDropdownSystem";
import theme from "../../theme";
import * as actions from "../../store/actions";
import useI18n from "../../hooks/useI18n";
import appTexts from "../../texts";

export interface DropDownState {
  id: "all" | string | null;
  all: boolean;
  selectedItems: Record<string, boolean>;
  setItemIds(ids: string[]): void;
  toggle(id: string): void;
  toggleAll(): void;
  close(): void;
  getSelection(): string[];
  clearSelection(): void;
}

export const useFeedItemDropdown = zustand<DropDownState>((set, get) => ({
  id: null,
  all: false,
  selectedItems: {} as Record<string, boolean>,
  setItemIds: (ids: string[]) => {
    const all = get().all;
    const data = get().selectedItems;
    const selectedClips = {} as Record<string, boolean>;
    ids.forEach((id) => {
      selectedClips[id] = all || data[id] || false;
    });
    set({ selectedItems: selectedClips });
  },
  toggle: (id: string) => {
    let all = get().all;
    const selectedClips = _.cloneDeep(get().selectedItems);
    selectedClips[id] = selectedClips[id] === true ? false : true;
    if (all) {
      all = !all;
    }
    set({ selectedItems: selectedClips, all });
    if (selectedClips[id]) {
      set({ id });
    } else {
      set({ id: null });
    }
  },
  toggleAll: () => {
    const all = !get().all;
    let id = get().id;
    let selectedClips = _.cloneDeep(get().selectedItems);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = all;
    });
    if (id === "all") {
      id = null;
    } else if (all) {
      id = "all";
    }
    set({ all, selectedItems: selectedClips, id });
  },
  close: () => {
    set({ id: null });
  },
  getSelection: () => {
    const selectedClips = get().selectedItems;
    return Object.keys(selectedClips).filter((k) => selectedClips[k]);
  },
  clearSelection: () => {
    let selectedClips = _.cloneDeep(get().selectedItems);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = false;
    });
    set({ selectedItems: selectedClips, id: null, all: false });
  },
  count: () => {
    const items = get().selectedItems;
    return Object.keys(items).filter((k) => items[k]).length;
  },
}));

export const FeedItemDropdown = () => {
  const t = useI18n();
  const projects = useLoggedState((s) => s.projects);
  const dropdown = useFeedItemDropdown();
  const ref = useDropdownSystem(dropdown.close, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [30, -30],
        },
      },
    ],
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(
    null as null | "archive" | "archive_read" | "publish"
  );
  const changeOption = (v: typeof option) => (e: React.MouseEvent) => {
    e.preventDefault();
    setOption(option === v ? null : v);
  };

  const submitArchive = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const ids = dropdown.getSelection();
    dispatch(actions.feeds.archiveItems(ids)).finally(() => dropdown.close());
  };

  const submitArchiveRead = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const ids = dropdown.getSelection();
    dispatch(actions.feeds.archiveItems(ids, ["read", "published"])).finally(
      () => dropdown.close()
    );
  };

  const submitPublish = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const ids = dropdown.getSelection();
    const projectId = (e.target as any).projectId.value;
    dispatch(actions.feeds.publishItems(ids, projectId)).finally(() =>
      dropdown.close()
    );
  };
  const count = dropdown.count();
  return (
    <>
      {/* <div className="dropdown-shadow"></div> */}
      <div
        ref={ref}
        className="dropdown-menu show"
        style={{
          zIndex: 2000,
          //position: "absolute",
          padding: "12px 8px",
          marginLeft: "30px !important",
          minWidth: "200px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={changeOption("archive")}>
          <Archive width={18} className="text-danger mr-1" />
          {t(
            dropdown.all
              ? appTexts.archiveAll
              : count > 1
              ? {
                  en: "Archive " + count + " mentions",
                  fr: "Archiver " + count + " mentions",
                }
              : appTexts.archive
          )}
        </button>
        {option === "archive" && (
          <InOptionForm
            onSubmit={submitArchive}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmArchive)}
              </button>
            </div>
          </InOptionForm>
        )}
        {dropdown.all && (
          <button
            className="dropdown-item"
            onClick={changeOption("archive_read")}
          >
            <Archive width={18} className="text-danger mr-1" />
            {t(appTexts.archiveRead)}
          </button>
        )}
        {option === "archive_read" && (
          <InOptionForm
            onSubmit={submitArchiveRead}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmArchive)}
              </button>
            </div>
          </InOptionForm>
        )}

        <button
          className="dropdown-item d-flex align-items-center"
          onClick={changeOption("publish")}
        >
          <FilePlus width={18} className="mr-1" />
          <span>
            {t(
              count === 1
                ? { en: "Add to report...", fr: "Ajouter au rapport..." }
                : {
                    en: "Add " + count + " mentions to report...",
                    fr: "Ajouter " + count + " mentions au rapport...",
                  }
            )}
          </span>
        </button>
        {option === "publish" && (
          <InOptionForm
            onSubmit={submitPublish}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <select
              name="projectId"
              className="form-control custom-select custom-select-sm"
              disabled={loading}
            >
              {projects.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.title}
                </option>
              ))}
            </select>
            <div className="text-center text-nowrap mt-1">
              <button
                className="d-block btn btn-sm btn-primary mr-2"
                type="submit"
              >
                {t({ en: "Add", fr: "Ajouter" })}
              </button>
            </div>
          </InOptionForm>
        )}
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
