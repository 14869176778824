import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import theme from "../../theme";

export function DrawerTitle({ title, lead }: { title: any; lead: any }) {
  return (
    <div>
      <h2 className="bold">{title}</h2>
      <p className="rr-t2 rr-mid-grey">{lead}</p>
    </div>
  );
}

export function DrawerSectionFrame({
  title,
  children,
}: {
  title: string;
  children?: any;
}) {
  return (
    <div className="mt-4 drawer-section">
      <h3 className="rr-t2b mb-2">{title}</h3>
      {children}
    </div>
  );
}

export function DrawerSectionBody({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "rr-bg-softer-grey border rounded p-3",
        className || ""
      )}
    >
      {children}
    </div>
  );
}

export function DrawerSection({
  className,
  title,
  children,
}: {
  className?: string;
  title: any;
  children?: any;
}) {
  return (
    <DrawerSectionFrame title={title}>
      <DrawerSectionBody className={className}>{children}</DrawerSectionBody>
    </DrawerSectionFrame>
  );
}

export function DrawerBody({ children }: { children?: any }) {
  return (
    <div
      className="px-4 pb-5 overflow-y-auto flex-grow-1"
      style={{ position: "relative" }}
    >
      {children}
    </div>
  );
}

export const DrawerFooter = styled.div.attrs((props) => ({
  ...props,
  className: classNames("px-4 py-3 border-top", props.className),
}))``;

export const DrawerSeparator = styled.div`
  background-color: ${theme.lighterGrey};
  color: ${theme.darkGrey};
  margin-left: -24px;
  margin-right: -24px;
  padding: 16px 24px;
  border-bottom: 1px solid ${theme.lightGrey};
`;
