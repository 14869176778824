import * as React from "react";

/**
 * A flashing value which will be back to init after ms.
 *
 * @param ms Number of milliseconds before turning off.
 */
export const useFlash = <T>(
  initialValue: T,
  ms: number = 2000
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [on, setValue] = React.useState(initialValue);
  React.useEffect(() => {
    if (on) {
      const to = setTimeout(() => setValue(initialValue), ms);
      return () => clearTimeout(to);
    }
  }, [on, initialValue, ms]);
  return [on, setValue];
};

export default useFlash;
