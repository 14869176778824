export const LANGS = ["en", "fr", "es"] as const;

export type Lang = typeof LANGS[number];

export const navLang = (navigator?.languages
  .map((l) => l.substring(0, 2).toLowerCase())
  .find((l) => LANGS.includes(l as any)) || "en") as Lang;

export const tr = <T>(l: Lang, t: Partial<Record<Lang, T>>): T =>
  t[l] || t["en"]!;

export type TFunction = <T>(t: Partial<Record<Lang, T>>) => T;
