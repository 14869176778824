import React from "react";
import { DrawerBody, DrawerSection, DrawerTitle } from ".";
import { api } from "../../api";
import { Paths } from "../../client";
import {
  addMonths,
  addYears,
  format,
  isAfter,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { subYears } from "date-fns/esm";
import fr from "date-fns/locale/fr";
import _ from "lodash";
import useI18n from "../../hooks/useI18n";
import { Download } from "react-feather";

/**
 * CFC drawer.
 */
export default function CfcDrawer() {
  const t = useI18n();
  const now = new Date();
  const beginOfYear = startOfYear(now);
  const halfOfYear = addMonths(beginOfYear, 6);
  const endOfYear = addYears(beginOfYear, 1);
  // we will display 3 periods, each period is 6 months long starting from the start of the year
  const periods = isAfter(now, halfOfYear)
    ? [
        { from: halfOfYear, to: endOfYear },
        { from: beginOfYear, to: halfOfYear },
        { from: subYears(halfOfYear, 1), to: beginOfYear },
      ]
    : [
        { from: beginOfYear, to: halfOfYear },
        { from: subMonths(beginOfYear, 6), to: beginOfYear },
        { from: subYears(beginOfYear, 6), to: subMonths(beginOfYear, 6) },
      ];
  return (
    <DrawerBody>
      <DrawerTitle
        title={t({ en: "CFC Declaration", fr: "Déclaration CFC" })}
        lead={undefined}
      ></DrawerTitle>
      <div>
        {periods.map((p) => (
          <CFCPeriod from={p.from} to={p.to} key={p.from.toISOString()} />
        ))}
      </div>
    </DrawerBody>
  );
}

/**
 * Display one CFC period table.
 */
function CFCPeriod({ from, to }: { from: Date; to: Date }) {
  const t = useI18n();
  const [data, setData] = React.useState<Paths.GetCfcUsage.Responses.$200>([]);
  const [url, setUrl] = React.useState<string>("");
  React.useEffect(() => {
    api
      .GetCfcUsage({
        from: from.toISOString(),
        to: to.toISOString(),
      })
      .then(({ data }) => setData(data));
    setUrl(
      `${
        process.env.REACT_APP_API_URL
      }/api/sources/cfc-usage/download.csv?from=${from.toISOString()}&to=${to.toISOString()}&access_token=${window.localStorage.getItem(
        "auth_token"
      )}`
    );
  }, [from, to]);
  return (
    <>
      <DrawerSection
        title={t({
          fr: (
            <>
              {format(subDays(to, 1), "yyyy")} Période du{" "}
              {format(from, "dd MMMM", { locale: fr })} au{" "}
              {format(subDays(to, 1), "dd MMMM", { locale: fr })}
            </>
          ),
          en: (
            <>
              {format(subDays(to, 1), "yyyy")} From {format(from, "dd/MM")} to{" "}
              {format(subDays(to, 1), "dd/MM")}
            </>
          ),
        })}
      >
        <table className="w-100">
          <thead>
            <tr>
              <th className="rr-t3b w-100">Client</th>
              <th className="rr-t3b">{t({ fr: "Coupures", en: "Clips" })}</th>
            </tr>
          </thead>
          <tbody>
            {_(data)
              .groupBy("workspace_id")

              .map((data, key) => (
                <tr key={key}>
                  <td className="rr-t3" width="100%">
                    {data[0].workspace_name}
                  </td>
                  <td className="rr-t3 text-right">
                    {data.reduce(
                      (acc, r) => acc + parseInt(r.cfc_pages as any),
                      0
                    )}
                  </td>
                </tr>
              ))
              .flatten()
              .value()}
          </tbody>
          {/* <tfoot>
            <th>Totaux</th>
            <th className="text-center">
              {data.reduce((acc, r) => acc + parseInt(r.cfc_pages as any), 0)}
            </th>
          </tfoot> */}
        </table>
        <div className="mt-2">
          <a
            rel="noreferrer"
            target="_blank"
            href={url}
            className="btn btn-sm btn-secondary"
          >
            <Download />
            {t({
              en: "Download detailed CSV",
              fr: "Télécharger le détail",
            })}
          </a>
        </div>
      </DrawerSection>
    </>
  );
}
