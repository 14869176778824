import cls from "classnames";
import { format } from "date-fns";
import React from "react";
import { Eye, FilePlus, Grid, Move, Star, Users } from "react-feather";
import theme from "../../theme";
import { numberF } from "../../utils/numberF";
import styled from "styled-components";
import { DraggableProvided } from "react-beautiful-dnd";
import { PageDropdown, usePageDropdown } from "./PageDropdown";
import useI18n from "../../hooks/useI18n";
import { useDispatch } from "../../hooks/useAppState";
import * as actions from "../../store/actions";

interface ClipCardProps {
  id: string;
  kind?: string;
  date?: Date;
  title: string;
  publicationName?: string | null;
  image?: string;
  views: number;
  users: number;
  loading?: boolean;
  disabled?: boolean;
  highlight?: boolean;
  mosaic?: boolean;
  onepage?: boolean;
  error?: string;
  draggable?: DraggableProvided;
  open?: () => void;
}

export default function ClipCard(props: ClipCardProps) {
  const t = useI18n();
  const dispatch = useDispatch();
  const selectState = usePageDropdown();
  const selected = selectState.all || selectState.selectedClips[props.id];
  React.useEffect(() => {
    (window as any).$('[data-toggle="tooltip"]').tooltip();
  }, []);
  const disabled = !props.onepage && !props.mosaic && !props.highlight;
  const toggleHighlight = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      actions.pages.setDesign([props.id], { highlight: !props.highlight })
    );
  };
  const toggleMosaic = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.pages.setDesign([props.id], { mosaic: !props.mosaic }));
  };
  const toggleOnepage = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.pages.setDesign([props.id], { onepage: !props.onepage }));
  };
  return (
    <Card
      ref={props.draggable?.innerRef}
      {...(props.draggable
        ? {
            ...props.draggable?.draggableProps,
            ...props.draggable?.dragHandleProps,
          }
        : {})}
      onClick={(e) => {
        e.preventDefault();
        props.open?.();
      }}
      className={cls("mb-3 card cursor-pointer", {
        selected,
        "border-danger": !!props.error,
        disabled: disabled,
        loading: props.loading,
      })}
    >
      <div className="card-body">
        <div className="d-flex">
          {/* check + move buttons */}
          <div className="d-flex flex-column justify-content-between mr-1">
            <div style={{ position: "relative", maxWidth: "28px" }}>
              <Check
                onClick={(e) => {
                  e.stopPropagation();
                  selectState.toggle(props.id);
                }}
                checked={selected}
                aria-expanded="false"
              />
              {selectState.id === props.id && <PageDropdown />}
            </div>
            <div style={{ position: "relative", maxWidth: "28px" }}>
              {/* {props.toggle &&
                (props.disabled ? (
                  <EyeOff
                    width="18"
                    height="18"
                    color={theme.grey}
                    style={{ margin: "2px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.toggle?.();
                    }}
                  />
                ) : (
                  <Eye
                    width="18"
                    height="18"
                    color={theme.grey}
                    style={{ margin: "2px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.toggle?.();
                    }}
                  />
                ))} */}
              <Move
                width="18"
                height="18"
                style={{ margin: "2px" }}
                color={theme.grey}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
          {/* info block */}
          <div
            className={cls(
              "w-100 flex-shrink-1 d-flex flex-column flex-lg-row justify-content-between",
              { "opacity-40": disabled }
            )}
          >
            {/* image and title grouped */}
            <div className="d-flex justify-content-between">
              <Image
                onClick={() => props.open?.()}
                className={cls("cursor-pointer", {
                  "bg-danger": !!props.error,
                })}
                style={
                  props.image
                    ? {
                        backgroundColor: theme.lightGrey,
                        backgroundImage: `url('${props.image}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }
                    : {}
                }
              />
              <div className="pl-2 pl-sm-3 flex-grow-1 flex-shrink-1">
                <Title
                  className={cls("cursor-pointer", {
                    "text-danger": !!props.error,
                    "rr-grey": props.loading,
                  })}
                  onClick={() => props.open?.()}
                >
                  {props.loading
                    ? t({
                        en: "Clipping, please wait...",
                        fr: "Travail en cours, merci de patienter...",
                      })
                    : props.error
                    ? t({ en: "Clip error", fr: "Erreur de coupure" })
                    : props.title}
                </Title>
                {(props.date || props.publicationName) && (
                  <div
                    className="mt-1 rr-t3 rr-mid-grey cursor-pointer"
                    onClick={() => props.open?.()}
                  >
                    {props.publicationName && (
                      <>
                        {props.publicationName}
                        {props.date != null && <span className="mx-1">-</span>}
                      </>
                    )}
                    {props.date ? format(props.date, "d MMM yyyy") : ""}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <div
                    onClick={toggleHighlight}
                    className={cls("cursor-pointer mr-1", {
                      "opacity-80": props.highlight,
                      "opacity-20": !props.highlight,
                    })}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t({
                      en: "Display clip in the Highlight section of your report",
                      fr: "Afficher cette coupure dans la section Highlight de votre rapport.",
                    })}
                  >
                    <Star width={18} height={18} />
                  </div>
                  <div
                    onClick={toggleMosaic}
                    className={cls("cursor-pointer mr-1", {
                      "opacity-80": props.mosaic,
                      "opacity-20": !props.mosaic,
                    })}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t({
                      en: "Display clip in the Mosaic section of your report",
                      fr: "Afficher cette coupure dans la section Mosaïque de votre rapport.",
                    })}
                  >
                    <Grid width={18} height={18} />
                  </div>
                  <div
                    onClick={toggleOnepage}
                    className={cls("cursor-pointer mr-1", {
                      "opacity-80": props.onepage,
                      "opacity-20": !props.onepage,
                    })}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t({
                      en: "Display clip details in a dedicated page of your report",
                      fr: "Afficher cette coupure et ses infos dans une page dédiée de votre rapport.",
                    })}
                  >
                    <FilePlus width={18} height={18} />
                  </div>
                </div>
              </div>
            </div>
            {/* Stats and button grouped */}
            <div className="my-3 ml-md-2 d-flex justify-content-between my-md-3">
              <Stats className="ml-0 d-flex align-self-center">
                <StatBlock>
                  <div>
                    {props.kind === "youtube" ? (
                      <div></div>
                    ) : (
                      <Users width={16} height={16} />
                    )}
                  </div>
                  <div className="d-none d-sm-block rr-t4">
                    {t({
                      en:
                        props.kind === "youtube" ? "Subscribers" : "Readership",
                      fr: "Audience",
                    })}
                  </div>
                  <div className="rr-t2b rr-mid-grey">
                    {numberF(props.users || 0)}
                  </div>
                </StatBlock>
                <Separator />
                <StatBlock>
                  <div>
                    {props.kind === "youtube" ? (
                      <Youtube
                        style={{
                          width: 30,
                          height: 30,
                          color: "#282828",
                        }}
                      />
                    ) : (
                      <Eye width={16} height={16} />
                    )}
                  </div>
                  <div className="d-none d-sm-block rr-t4">
                    {t({ en: "Views", fr: "Vues" })}
                  </div>
                  <div className="rr-t2b rr-mid-grey">
                    {numberF(props.views || 0)}
                  </div>
                </StatBlock>
              </Stats>
              <div className="align-self-center ml-3">
                <a
                  className="btn btn-primary btn-sm text-nowrap"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    props.open?.();
                  }}
                >
                  <span className="d-375-none">
                    {t({ en: "Details", fr: "Détails" })}
                  </span>
                  <span className="d-none d-375-block">
                    {t({ en: "Clips details", fr: "Détails" })}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

const Card = styled.div.attrs((props) => props)`
  border: 2px solid ${theme.lightGrey};

  &:hover {
    border: 2px solid ${theme.blue};
  }

  &.disabled {
    border: 2px solid rgba(0, 0, 0, 0.05);
  }

  &.loading {
    border: 2px dashed ${theme.lightGrey};
  }

  .card-body {
    padding: 10px;
    oveflow: hidden;
    @media (min-width: 375px) {
      padding: 15px;
    }
    @media (min-width: 768px) {
      padding: 1.25rem;
    }
  }

  .card-body > .d-flex {
    row-gap: 10px;
    column-gap: 10px;
    @media (min-width: 768px) {
      row-gap: 16px;
      column-gap: 16px;
    }
  }
`;

export const Title = styled.div.attrs((props) => ({
  className: props.className + " rr-t2b",
}))`
  max-height: 5.5em;
  overflow: hidden;
  max-width: 120px;
  //word-break: break-all;
  @media (min-width: 375px) {
    max-width: 100%;
    word-break: break-word;
  }
`;

const Stats = styled.div`
  color: ${theme.midGrey};
`;

const StatBlock = styled.div`
  text-align: center;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  @media (min-width: 768px) {
    width: auto;
    &:first-child {
      padding: 0px 10px;
    }
    &:last-child {
      padding: 0px 0px 0px 10px;
    }
  }
  @media (min-width: 1200px) {
    width: 100px;
  }
`;

const Image = styled.div`
  border-radius: 4px;
  max-width: 128px;
  max-height: 96px;
  min-width: 100px;
  min-height: 80px;
  @media (min-width: 375px) {
    min-width: 128px;
    min-height: 96px;
  }
`;

const Separator = styled.div`
  width: 2px;
  background-color: #e9e9e9;
  margin: 16px;
`;

export const Check = styled.div<{ checked?: boolean }>`
  margin: 2px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `
    background-color: ${theme.blue};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    background-position: center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
  `
      : `
      border: 2px solid ${theme.grey};`}
`;

const Youtube = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
  </svg>
);
