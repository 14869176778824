import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { getAuthToken } from "../api";
import { useDispatch } from "./useAppState";
import * as actions from "../store/actions";

/**
 * Opens a socket.io connection to the server and listen to events for the given project or workspace.
 * This hook will refresh project and pages when needed.
 *
 * @param workspaceId The workspace to listen to.
 * @param projectId The project to listen to.
 * @return the socket.io client.
 */
export default function useSocketIo(
  args:
    | {
        projectId: string;
      }
    | {
        workspaceId: string;
      }
) {
  const [socket, setSocket] = useState(null as any);
  const dispatch = useDispatch();
  useEffect(() => {
    const url = process.env.REACT_APP_API_URL || "";
    const s = io(url, {
      auth: {
        token: getAuthToken(),
        ...args,
      },
    });
    s.onAny((...args) => {
      const [action, id] = args;
      switch (action) {
        case "page updated":
          dispatch(actions.pages.refreshPage(id));
          break;
        case "page created":
          dispatch(actions.pages.refreshPage(id));
          break;
        case "page deleted":
          dispatch({ type: "DEL_PAGE", pageId: id });
          break;
        case "feed updated":
          dispatch(actions.feeds.refreshFeed(id)).then(() =>
            dispatch(actions.feeds.refreshNotifications())
          );
          break;
      }
    });
    setSocket(s);
    return () => {
      s.close();
      setSocket(null);
    };
  }, ["projectId" in args ? args.projectId : args.workspaceId]);
  return socket;
}
