import {
  navigate,
  RouteComponentProps,
  useLocation,
  useParams,
} from "@reach/router";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import React, { useEffect, useState } from "react";
import { api } from "../../api";
import { Components } from "../../client";
import { FullPage } from "../../components/LoggedLayout";
import { toast } from "react-toastify";

export function AdminSystemStatus(_props: RouteComponentProps) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [filter, setFilter] = useState<"all" | "success" | "failure">(
    (query.get("filter") as "all" | "success" | "failure") || "all"
  );
  const [status, setStatus] = useState<Components.Schemas.Status[]>([]);
  useEffect(() => {
    api.SystemStatus().then((res) => {
      setStatus(res.data);
    });
  }, []);
  return (
    <>
      <FullPage>
        <div className="m-2 p-4">
          <h1 className="rr-h2">System Status</h1>
          <div className="d-flex justify-content-between">
            <p className="lead">
              Results:{" "}
              {status?.map((s, i) => (
                <a className="mr-1" href={"#" + s.id}>
                  {s.status === -1 ? "❌" : "✅"}
                </a>
              ))}
            </p>
            <div>
              <select
                onChange={(e) => {
                  setFilter(e.target.value as any);
                  navigate(`/admin/status?filter=${e.target.value}`);
                }}
              >
                <option value="all">All</option>
                <option value="success">✅ Success</option>
                <option value="failure">❌ Failure</option>
              </select>
            </div>
          </div>
          {status
            ?.filter((s) =>
              filter === "all"
                ? true
                : filter === "success"
                ? s.status === 1
                : s.status !== 1
            )
            .map((s) => (
              <StatusView key={s.id} status={s} />
            ))}
        </div>
      </FullPage>
    </>
  );
}

function StatusView({ status }: { status: Components.Schemas.Status }) {
  const result = status.result;
  return (
    <div id={status.id} style={{ paddingTop: 65, marginTop: -60 }}>
      <div className="card p-3" style={{ marginTop: 10 }}>
        <div className="d-flex justify-content-between">
          <div>
            <h6>
              {status.status === 1 ? (
                <>✅</>
              ) : status.status === -1 ? (
                <>❌</>
              ) : (
                <>🕸️</>
              )}{" "}
              {status.id}
            </h6>
            {status.last_success_at && (
              <div>
                <em>
                  Last success:{" "}
                  {format(
                    new Date(status.last_success_at),
                    "yyyy-MM-dd . HH:mm",
                    {
                      locale: fr,
                    }
                  )}{" "}
                  UTC
                </em>
              </div>
            )}
            {status.last_failure_at && (
              <div>
                <em>
                  Last failure:{" "}
                  {format(
                    new Date(status.last_failure_at),
                    "yyyy-MM-dd . HH:mm",
                    {
                      locale: fr,
                    }
                  )}{" "}
                  UTC
                </em>
              </div>
            )}
            {result && (
              <div>
                {result.thrown && (
                  <>
                    <p className="lead mb-0 text-danger">Error Thrown:</p>
                    <pre className="alert alert-danger">{result.thrown}</pre>
                  </>
                )}
                <ul className="list-unstyled pl-4 mb-0">
                  {result.tests?.map((t, i) => (
                    <li key={i} className={t.result ? "" : "text-danger"}>
                      {t.result ? <>👍 {t.title}</> : <>💩 {t.title}: </>}
                      {!t.result && (
                        <span className="text-danger font-weight-bold">
                          {t.error}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
                {(result.urls || []).map((url, i) => (
                  <div key={i}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
          {result && result.screenshots && result.screenshots.length > 0 && (
            <div className="d-flex mt-2">
              {result.screenshots?.map((s) => (
                <div className="text-center">
                  <img
                    className="img-fluid border rounded"
                    src={s.url + "?v=" + result.start}
                    alt={s.comment}
                    style={{ maxWidth: "200px" }}
                  />
                  <br />
                  <small>{s.comment}</small>
                </div>
              ))}
            </div>
          )}
          <div>
            <RestartButton status={status} />
          </div>
        </div>
      </div>
    </div>
  );
}

function RestartButton({ status }: { status: Components.Schemas.Status }) {
  const [loading, setLoading] = useState(false);
  const handleRestart = async () => {
    setLoading(true);
    await api.SystemStatusRestart({ id: status.id });
    setLoading(false);
    toast.success("System Status scheduled.");
  };
  return (
    <button
      className="btn btn-outline-primary"
      onClick={handleRestart}
      disabled={loading}
    >
      Restart
    </button>
  );
}
