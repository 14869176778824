/**
 * Workspaces menu tree.
 */
import React, { useEffect } from "react";
import { Plus, ChevronDown, ChevronRight, MoreHorizontal } from "react-feather";
import { Link, navigate } from "@reach/router";
import styled from "styled-components";
import theme from "../../theme";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import * as actions from "../../store/actions";
import imageFile from "../../stories/assets/splash.jpg";
import { useWorkspaceDropdown, WorkspaceDropdown } from "./WorkspaceDropdown";
import useI18n from "../../hooks/useI18n";
import { State } from "../../store/types";
import {
  CreateWorkspaceDropdown,
  useCreateWorkspaceDropdown,
} from "./CreateWorkspaceDropdown";
import { getProjectTitle, getWorkspaceName } from "../../model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Project({
  id,
  icon,
  name,
  current,
}: {
  id: string;
  icon: string;
  name: string;
  current?: boolean;
}) {
  return (
    <Link
      to={`/projects/${id}/clips`}
      className={"d-block d-flex align-items-center cursor-pointer"}
      style={
        current
          ? {
              backgroundColor: theme.lightGrey,
              marginLeft: "-22px",
              padding: "8px 8px 8px 38px",
              marginRight: "-22px",
            }
          : {
              padding: "8px 16px",
            }
      }
    >
      <ProjectIcon src={icon} />
      <span
        className="name rr-t3b rr-mid-grey text-nowrap"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </span>
    </Link>
  );
}

export const ProjectIcon = styled.span<{ src: string; size?: number }>`
  display: block;
  width: ${(props) => props.size || 16}px;
  height: ${(props) => props.size || 16}px;
  border-radius: 2px;
  background-color: ${theme.lightGrey};
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
  flex-shrink: 0;
`;

function Workspace({ id }: { id: string }) {
  const t = useI18n();
  const dropdown = useWorkspaceDropdown();
  const dispatch = useDispatch();
  const currentWP = useAppState((s) => s.workspace);
  const workspace = useAppState((s) => s.workspaces.find((w) => w.id === id));
  const notifications = useAppState((s) => s.notifications[id] || 0);
  const hasFeedsFeat = useAppState((s) => State.getTeam(s)?.feat_feeds);
  const isCurrent = workspace && workspace?.id === currentWP?.id;
  const projects = useAppState((s) =>
    s.projects.filter((p) => p.workspace_id === id)
  );
  const currentPR = useAppState((s) => s.project);
  const [creating, setCreating] = React.useState(false);
  const [closed, setClosed] = React.useState(true);
  React.useEffect(() => {
    if (workspace && isCurrent) {
      setClosed(false);
      //setClosed(false);
    }
  }, [isCurrent, workspace, closed]);
  if (!workspace) {
    return <></>;
  }
  return (
    <>
      <WorkspaceTitle>
        <div>
          <Link
            to={`/workspaces/${workspace.id}`}
            className="rr-mid-grey"
            onClick={(e) => {
              if (isCurrent) {
                //                e.preventDefault();
                setClosed(!closed);
              } else {
                setClosed(false);
              }
            }}
          >
            {isCurrent && !closed ? (
              <ChevronDown height="18" className="rr-mid-grey" />
            ) : (
              <ChevronRight height="18" className="rr-grey" />
            )}
            <span>{getWorkspaceName(workspace, t)}</span>
          </Link>
        </div>
        <div className="options" style={{ position: "relative" }}>
          <BtnCreate
            className="align-self-start"
            onClick={(e) => dropdown.select(workspace.id, "tree")}
          >
            <MoreHorizontal height="16" />
          </BtnCreate>
          <BtnCreate
            className="align-self-start"
            onClick={(e) => {
              e.preventDefault();
              if (creating) {
                return;
              }
              setCreating(true);
              dispatch(
                actions.projects.createProject({
                  title: t({ en: "New report", fr: "Nouveau rapport" }),
                  workspace_id: workspace.id,
                })
              )
                .then((p) => navigate(`/projects/${p.id}/clips`))
                .finally(() => setCreating(false));
            }}
          >
            <Plus height="16" />
          </BtnCreate>
        </div>
        {dropdown.isSelected(workspace.id, "tree") && (
          <WorkspaceDropdown workspace={workspace} position="right" />
        )}
      </WorkspaceTitle>
      {isCurrent && !closed && (
        <div className="projects mb-4">
          {hasFeedsFeat && (
            <Link
              className="d-block font-weight-bold"
              to={`/workspaces/${workspace.id}/feeds`}
              style={{
                padding: "2px 16px",
              }}
            >
              <FontAwesomeIcon
                icon="rss"
                style={{
                  width: "16px",
                  height: "16px",
                  marginRight: "8px",
                }}
              />
              <span className="rr-t3b rr-mid-grey text-nowrap">
                Monitoring 
                {notifications > 0 && (
                  <span className="badge badge-primary">{notifications}</span>
                )}
              </span>
            </Link>
          )}
          {projects.map((p) => (
            <Project
              key={p.id}
              id={p.id}
              icon={p.logo || imageFile}
              name={getProjectTitle(p, t)}
              current={p.id === currentPR?.id}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default function WorkspacesTree() {
  const t = useI18n();
  const workspaces = useAppState((s) => s.workspaces.map((w) => w.id));
  const team = useAppState(State.getTeam);
  const dispatch = useDispatch();
  const createDropdown = useCreateWorkspaceDropdown();
  useEffect(() => {
    const fn = () => {
      console.log("refreshing notifications");
      dispatch(actions.feeds.refreshNotifications());
    };
    fn();
    const to = setInterval(fn, 30_000);
    return () => clearInterval(to);
  }, []);
  return (
    <div className="mx-4 my-3">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <Link to="/workspaces" className="rr-grey uppercase rr-t4 d-block mb-2">
          {t({
            en: "My client Workspaces",
            fr: team?.cfc_enabled ? "Mes clients" : "Mes espaces",
          })}
        </Link>
        {/* <BtnCreate
          className="align-self-start"
          onClick={(e) => {
            e.preventDefault();
            if (creating) {
              return;
            }
            setCreating(true);
            dispatch(actions.workspaces.createWorkspace({ name: "No name" }))
              .then((w) => navigate(`/workspaces/${w.id}`))
              .finally(() => setCreating(false));
          }}
        >
          <Plus height="16" />
        </BtnCreate> */}
      </div>
      <div>
        {workspaces.map((id) => (
          <Workspace key={id} id={id} />
        ))}
        <div className="mt-n1">
          <BtnCreate
            className="align-self-start small opacity-60"
            onClick={(e) => {
              e.preventDefault();
              createDropdown.select("create");
            }}
          >
            <Plus height="14" />
            {t({ en: "New client workspace", fr: "Nouvel espace client" })}
          </BtnCreate>
          {createDropdown.isSelected("create") && (
            <CreateWorkspaceDropdown position="right" />
          )}
        </div>
      </div>
    </div>
  );
}

const BtnCreate = styled.button`
  border: 0px;
  height: 16px;
  background-color: transparent;
  padding: 0px 0px 0px 0px !important;
  margin: 0px !important;
  color: ${theme.midGrey};
  svg {
    vertical-align: top;
  }
  // small hover highlight
  &:hover {
    color: ${theme.blue};
  }
`;

const WorkspaceTitle = styled.div.attrs(() => ({
  className:
    "rr-t3b rr-mid-grey d-flex align-items-center justify-content-between",
}))`
  padding: 4px 0px;
  height: 24px;
  margin-bottom: 8px;
  position: relative;
  .options {
    display: none;
  }
  &:hover .options {
    display: inline;
  }
`;
