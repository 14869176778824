import * as React from "react";
import { useDispatch } from "../../hooks/useAppState";
import * as actions from "../../store/actions";
import useDropdownSystem, {
  createDropdownState,
} from "../../hooks/useDropdownSystem";
import useI18n from "../../hooks/useI18n";
import texts from "../../texts";
import { navigate } from "@reach/router";

export const useCreateWorkspaceDropdown = createDropdownState();

export const CreateWorkspaceDropdown = ({
  position = "right",
}: {
  position?: "right" | "left" | "auto";
}) => {
  const t = useI18n();
  const dropdown = useCreateWorkspaceDropdown();
  const ref = useDropdownSystem(dropdown.close, {
    placement: "bottom-end",
    strategy: "absolute",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(
    t({ en: "New Workspace", fr: "Nouvel Espace" })
  );
  const submitCreate = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(actions.workspaces.createWorkspace({ name: name || "" }))
        .then((w) => navigate(`/workspaces/${w.id}`))
        .finally(() => dropdown.close());
    }
  };
  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        ref={ref}
        className={"dropdown-menu show dropdown-menu-" + position}
        style={{
          zIndex: 2000,
          position: "absolute",
          padding: "12px 8px",
          minWidth: "200px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={submitCreate}>
          <div>
            <label className="rr-t4 text-nowrap">
              {t({ en: "Workspace name", fr: "Nom de l'espace" })}
            </label>
            <input
              className="form-control"
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <button
              className="bg-none text-primary border-0 px-0 py-0 mt-2 rr-cta"
              type="submit"
            >
              {t(texts.create)}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
