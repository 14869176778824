const FB_EMBED =
  /https:\/\/www.facebook.com\/plugins\/(post|video)\.php\?href=(.*?)&/;

const TWITTER_SUB_DOMAIN = /^https:\/\/(\D+\.)?twitter.com.*$/;

/**
 * Guess an url from a string
 */
export default function cleanUrl(str: string): string {
  str = str.trim();
  const fbUrl = str.match(FB_EMBED);
  if (fbUrl) {
    const url = decodeURIComponent(fbUrl[2]);
    if (fbUrl[1] === "video") {
      return url;
    }
    // https://www.facebook.com/permalink.php?story_fbid=pfbid0YgP7mDojuuY1uHfXqccJKRifmFsjRm9YxHGVd2eHbbBuRfdBpRWQtamkSTGQ8rCWl&id=100068224279655
    // https://www.facebook.com/100068224279655/posts/pfbid0YgP7mDojuuY1uHfXqccJKRifmFsjRm9YxHGVd2eHbbBuRfdBpRWQtamkSTGQ8rCWl
    const permalink = url.match(
      /https:\/\/www\.facebook\.com\/permalink\.php\?story_fbid=(.*)&id=(.+)/
    );
    if (permalink) {
      return (
        "https://www.facebook.com/" + permalink[2] + "/posts/" + permalink[1]
      );
    }
    return url;
  }
  const twitterMatch = str.match(TWITTER_SUB_DOMAIN);
  if (twitterMatch) {
    return str.replace(twitterMatch[1], "");
  }
  if (str.indexOf("http") === 0) {
    if (str.startsWith("http://www.instagram"))
      return str.replace(/http:/, "https:");
    return str;
  }
  if (str.indexOf("http") > 0) {
    return str.replace(/^.+http/, "http");
  }
  // add http when required
  if (str.startsWith("://")) {
    str = str.slice(3);
  }
  if (!str.startsWith("http")) {
    str = "https://" + str;
  }
  return str;
}
