import * as React from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { CardPage, Label, Lead } from "../components/CardPage";
import { ApiError } from "../api";
import * as actions from "../store/actions";
import classnames from "classnames";
import { useDispatch } from "../hooks/useAppState";
import useI18n, { useLang } from "../hooks/useI18n";
import GoogleButton from "../components/auth/GoogleButton";
import { getAuthUrl } from "../utils/oauth/gogole";

export function Signin(_props: RouteComponentProps) {
  const t = useI18n();
  const lang = useLang();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    lang: lang,
    login: "",
    password: "",
    loading: false,
    error: null as ApiError | null,
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.getAttribute("name")!;
    const value: string = e.target.value;
    setState({ ...state, [name as any]: value, error: null });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.loading) {
      return;
    }
    dispatch(actions.signin({ ...state, lang }))
      .then(() => navigate("/"))
      .catch((e: any) => {
        console.log(e);
        const error = (e.response && e.response.data) || null;
        setState({ ...state, loading: false, error });
      });
  };
  const errors = (state.error && state.error.fields) || {};
  const errorMessage = (field: string) =>
    (errors[field] && errors[field].message) || "";
  const onOauth = (provider: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    const redirect = `${process.env.REACT_APP_URL}/oauth/${provider}/callback`;
    const state = {
      redirect,
    };
    const url = getAuthUrl(redirect, JSON.stringify(state));
    window.location.href = url.toString();
  };
  return (
    <CardPage
      coloredBox={t({
        en: (
          <>
            <h1 className="rr-t1b rr-dark-grey">Log In.</h1>
            <br />
            <Lead>Send Coverage Reports Your Customers Love.</Lead>
          </>
        ),
        fr: (
          <>
            <h1 className="rr-t1b rr-dark-grey">Connexion.</h1>
            <br />
            <Lead>Vos clients adoreront vos rapports.</Lead>
          </>
        ),
      })}
      topnav={
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <a href="https://www.reachreport.io">
              <img src="/back.svg" alt="back" />
            </a>
          </p>
          <p className="rr-t3 rr-mid-grey text-right">
            {t({
              en: <span>Don't have an account yet?</span>,
              fr: <span>Vous n'avez pas encore de compte ?</span>,
            })}
            <span className="d-block d-md-inline">
              <Link to="/signup">
                <span className="ml-1 px-1 btn btn-outline-primary btn-sm">
                  {t({ fr: "Inscrivez-vous", en: "Register Now" })}
                </span>
              </Link>
            </span>
          </p>
        </div>
      }
    >
      <img
        height="44"
        src="/reachreport_text.svg"
        //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
        alt="ReachReport.io"
        className="d-none d-md-inline-block"
      />
      <form onSubmit={handleSubmit} className="text-left mt-3 pb-5">
        <div className="form-group">
          <Label>{t({ en: "Email:", fr: "Email :" })}</Label>
          <input
            className={classnames("form-control", {
              "is-invalid": !!errors["input.email"],
            })}
            type="text"
            name="login"
            value={state.login}
            onChange={handleChange}
          />
          <div className="invalid-feedback">{errorMessage("input.email")}</div>
        </div>
        <div className="form-group">
          <Label>{t({ en: "Password:", fr: "Mot de passe :" })}</Label>
          <input
            className={classnames("form-control", {
              "is-invalid": !!errors["input.password"],
            })}
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
          />
          <div className="invalid-feedback">
            {errorMessage("input.password")}
          </div>
          {state.error && (
            <div className="text-danger">
              {t({
                en: (
                  <>
                    😱 Unknown email or bad password! Please try again or use
                    the lost password link:
                  </>
                ),
                fr: (
                  <>
                    😱 Email inconnu ou mot de passe erroné ! Merci d'essayer à
                    nouveau ou d'utiliser le lien suivant :
                  </>
                ),
              })}
            </div>
          )}
          <p className="mt-3 rr-t3 text-center">
            <Link to="/auth/forgot" className="text-underline">
              {t({
                en: "I forgot my password",
                fr: "J'ai oublié mon mot de passe",
              })}
            </Link>
          </p>
        </div>
        <div className="py-2 text-center">
          <button type="submit" className="btn btn-primary px-5">
            {t({ en: "Sign in", fr: "Entrer" })}
          </button>
        </div>
        <div className="text-center mt-4">
          <hr className="mb-n1" />
          <div className="mt-n3 pt-1">
            <div className="mt-n2 pb-3 px-2 bg-white d-inline-block mx-auto">
              {t({ en: "or", fr: "ou" })}
            </div>
          </div>
          <GoogleButton
            label={t({
              en: "Sign in with Google",
              fr: "Connectez-vous avec Google",
            })}
            signin={onOauth}
          />
        </div>
      </form>
      <div className="text-center pb-5 d-md-none">
        <img
          height="32"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
        />
      </div>
    </CardPage>
  );
}
