export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const theme = {
  blue: "#0386A3",
  darkBlue: "#111856",
  lightBlue: "#0386a3",
  lightestBlue: "#eefaff",

  darkGrey: "#333333",
  midGrey: "#6B6C6F",
  grey: "#9BA3B0",
  lightGrey: "#E9E9E9",
  lighterGrey: "#F9F9F9",
  white: "#FFFFFF",

  primary: "#0386A3",

  accent: "#ff611d",
  orange: "#ff611d",

  green: "#48d597",
  lightGreen: "#d0ffea",

  danger: "#ff611d",
  lightDanger: "#FFD8C8",

  media: {
    up: {
      sm: `min-width: ${breakpoints.sm}px`,
      md: `min-width: ${breakpoints.md}px`,
      lg: `min-width: ${breakpoints.lg}px`,
      xl: `min-width: ${breakpoints.xl}px`,
    },
    down: {
      xs: `min-width: ${breakpoints.xs - 0.02}px`,
      sm: `min-width: ${breakpoints.md - 0.02}px`,
      md: `min-width: ${breakpoints.lg - 0.02}px`,
      lg: `max-width: ${breakpoints.xl - 0.02}px`,
    },
    // TODO: only https://getbootstrap.com/docs/4.1/layout/overview/
  },
};

export default theme;
