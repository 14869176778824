import * as React from "react";
import { useDispatch } from "../../hooks/useAppState";
import * as actions from "../../store/actions";
import { Trash, Type } from "react-feather";
import theme from "../../theme";
import styled from "styled-components";
import { Workspace } from "../../store/types";
import useDropdownSystem, {
  createDropdownState,
} from "../../hooks/useDropdownSystem";
import useI18n from "../../hooks/useI18n";
import texts from "../../texts";
import { getWorkspaceName } from "../../model";

export const useWorkspaceDropdown = createDropdownState();

export const WorkspaceDropdown = ({
  workspace,
  position = "right",
}: {
  workspace: Workspace;
  position?: "right" | "left" | "auto";
}) => {
  const t = useI18n();
  const dropdown = useWorkspaceDropdown();
  const ref = useDropdownSystem(dropdown.close, {
    placement: "bottom-end",
    strategy: "absolute",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(
    null as null | "rename" | "delete"
  );
  const [name, setName] = React.useState(getWorkspaceName(workspace, t));
  const displayDeleteModal = (e: any) => {
    setOption(option !== "delete" ? "delete" : null);
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(actions.workspaces.deleteWorkspace(workspace.id)).finally(() =>
        dropdown.close()
      );
    }
  };
  const displayRenameModal = (e: any) => {
    setOption(option !== "rename" ? "rename" : null);
  };
  const submitRename = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(
        actions.workspaces.updateWorkspace(workspace.id, { name })
      ).finally(() => dropdown.close());
    }
  };
  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        ref={ref}
        className={"dropdown-menu show dropdown-menu-" + position}
        style={{
          zIndex: 2000,
          position: "absolute",
          padding: "12px 8px",
          minWidth: "200px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={displayRenameModal}>
          <Type width={18} className="mr-1 rr-grey" /> {t(texts.rename)}
        </button>
        {option === "rename" && (
          <InOptionForm onSubmit={submitRename}>
            <div>
              <label className="rr-t4 text-nowrap">
                {t({ en: "Change name", fr: "Renommer" })}
              </label>
              <input
                autoFocus
                className="form-control"
                type="text"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
              <button
                className="bg-none text-primary border-0 px-0 py-0 mt-3 rr-cta"
                type="submit"
              >
                {t(texts.rename)}
              </button>
            </div>
          </InOptionForm>
        )}

        <button className="dropdown-item" onClick={displayDeleteModal}>
          <Trash width={18} className="text-danger mr-1" /> {t(texts.delete)}
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(texts.confirm)}
              </button>
            </div>
          </InOptionForm>
        )}
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
