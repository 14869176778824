import { Link, RouteComponentProps } from "@reach/router";
import { format } from "date-fns";
import React from "react";
import { api } from "../api";
import { CardPage } from "../components/CardPage";
import useI18n from "../hooks/useI18n";
import { Team } from "../store/types";

type Props = { userId: string; teamId: string; code: string };

export function ExtendTrial(props: RouteComponentProps<Props>) {
  const { userId, teamId, code } = props;
  return <ExtendTrialView userId={userId!} teamId={teamId!} code={code!} />;
}

function ExtendTrialView({ userId, teamId, code }: Props) {
  const t = useI18n();

  const [state, setState] = React.useState({
    loading: true,
    team: null as Team | null,
    error: null as any,
  });

  React.useEffect(() => {
    api
      .ExtendTrial({ userId }, { teamId, code })
      .then((res) => res.data)
      .then((team) => setState({ loading: false, team, error: null }))
      .catch((error) => setState({ loading: false, error, team: null }));
  }, [userId, teamId, code]);

  return (
    <CardPage
      coloredBox={t({
        en: <h1>Extend your trial.</h1>,
        fr: <h1>Prolongez votre période d'essai.</h1>,
      })}
      topnav={
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <a href="https://www.reachreport.io">
              <img src="/back.svg" alt="back" />
            </a>
          </p>
          <p>
            {t({
              en: <span>Already have an account?</span>,
              fr: <span>Vous avez déjà un compte ?</span>,
            })}
            <Link to="/signin">
              <span className="ml-3 btn btn-outline-primary bg-white text-primary">
                {t({ en: "Sign in", fr: "Connectez-vous" })}
              </span>
            </Link>
          </p>
        </div>
      }
    >
      <img
        height="44"
        src="/reachreport_text.svg"
        //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
        alt="ReachReport.io"
        className="d-none d-md-inline-block"
      />
      <div className="mt-4">
        {state.loading && (
          <p className="lead">
            {t({ en: "Please wait...", fr: "Merci de patienter..." })}
          </p>
        )}
        {state.team && (
          <>
            <p className="lead mb-0">
              {t({
                en: "Thank you for your interest!",
                fr: "Merci de votre intérêt !",
              })}
            </p>
            <p className="lead mt-0 mb-5">
              <br />{" "}
              {t({
                en: "Your trial has been extended to",
                fr: "Votre période d'essai a été prolongée jusqu'à",
              })}
              <br />{" "}
              <strong>
                {format(
                  new Date(state.team.plan_period_end || Date.now()),
                  "PPP"
                )}
              </strong>
            </p>
            <p>
              <Link to="/signin">
                <span className="btn btn-accent btn-lg d-block w-100">
                  {t({ en: "Sign in", fr: "Se connecter" })}
                </span>
              </Link>
            </p>
          </>
        )}
        {state.error && (
          <>
            <p className="lead text-error">{state.error.toString()}</p>
          </>
        )}
      </div>
    </CardPage>
  );
}
