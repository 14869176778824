import { toast } from "react-toastify";

export const saved = () => toast.success("Saved", { autoClose: 1000 });

export const success = (
  text: string,
  params = { autoClose: 2000, position: "top-right" } as const
) => toast.success(text, params);

export const error = (err: string, autoClose = 2000) =>
  toast.error(err, {
    position: "top-right",
    autoClose,
  });
