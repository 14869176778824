import * as React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import classnames from "classnames";
import { CardPage, Label } from "../../components/CardPage";
import { api, ApiError } from "../../api";
import useI18n from "../../hooks/useI18n";

export function ForgotPassword(_props: RouteComponentProps) {
  const t = useI18n();
  const [state, setState] = React.useState({
    email: "",
    loading: false,
    success: false,
    error: null as ApiError | null,
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.getAttribute("name")!;
    const value: string = e.target.value;
    setState({ ...state, [name as any]: value });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.loading) {
      return;
    }
    setState({ ...state, loading: true, error: null });
    api
      .CreateResetToken({}, { email: state.email.trim().toLowerCase() })
      .then(() => setState({ ...state, success: true }))
      .catch((e: any) => {
        const error = (e.response && e.response.data) || null;
        console.log(e);
        setState({ ...state, loading: false, error });
      });
  };
  const errors = (state.error && state.error.fields) || {};
  const errorMessage = (field: string) =>
    (errors[field] && errors[field].message) || "";
  return (
    <CardPage
      topnav={
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <a href="https://www.reachreport.io">
              <img src="/back.svg" alt="back" />
            </a>
          </p>
          <p className="rr-t3 rr-mid-grey text-right">
            <span>
              {t({
                en: "Don't have an account yet?",
                fr: "Vous n'avez pas encore de compte ?",
              })}
            </span>
            <span className="d-block d-md-inline">
              <Link
                to="/signup"
                className="ml-1 px-1 btn btn-outline-primary btn-sm"
              >
                {t({ en: "Register now", fr: "Inscrivez-vous" })}
              </Link>
            </span>
          </p>
        </div>
      }
      coloredBox={
        <h1>
          {t({ en: "Forgot your password?", fr: "Mot de passe oublié ?" })}
        </h1>
      }
    >
      <div className="mb-3">
        <img
          height="44"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
          className="d-none d-md-inline-block"
        />
      </div>
      {state.success ? (
        t({
          en: (
            <>
              <h2>Check your inbox</h2>
              <p>We just sent you an email containing reset instructions!</p>
            </>
          ),
          fr: (
            <>
              <h2>Vérifiez votre boite mail</h2>
              <p>
                Nous venons d'envoyer un mail contenant les instructions qui
                vous permettront de changer votre mot de passe !
              </p>
            </>
          ),
        })
      ) : (
        <>
          <p className="lead">
            {t({
              en: (
                <>
                  Give us your account email and we will send you a reset link.
                </>
              ),
              fr: (
                <>
                  Saisissez l'email de votre compte, vous recevrez un mail avec
                  un lien à suivre.
                </>
              ),
            })}
          </p>
          <form onSubmit={handleSubmit} className="text-left pb-5">
            <div className="form-group">
              <Label>{t({ en: "Your Email:", fr: "Votre email :" })}</Label>
              <input
                className={classnames({
                  "form-control": true,
                  "is-invalid": !!errors["input.email"],
                })}
                type="email"
                name="email"
                placeholder={t({
                  en: "john@clark.com",
                  fr: "louise@leroux.fr",
                })}
                value={state.email}
                onChange={handleChange}
                disabled={state.loading}
              />
              <div className="invalid-feedback">
                {errorMessage("input.email")}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary px-5"
                disabled={state.loading}
              >
                {t({ en: "Get new password", fr: "Nouveau mot de passe" })}
              </button>
            </div>
          </form>
        </>
      )}
      <div className="text-center pb-5 d-md-none">
        <img
          height="32"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
        />
      </div>
    </CardPage>
  );
}
