import { Components } from "../client";

export type User = Components.Schemas.User;
export type Page = Components.Schemas.Page;
export type Session = Components.Schemas.Session;
export type Project = Components.Schemas.Project;
export type File = Components.Schemas.File;
export type PageStat = Components.Schemas.PageStat;
export type Share = Components.Schemas.Share;
export type ProjectWithUser = Components.Schemas.ProjectWithUserAndWorkspace;
export type Team = Components.Schemas.Team;
export type Workspace = Components.Schemas.Workspace;
export type Membership = Components.Schemas.Membership;
export type Design = Components.Schemas.Design;
export type DesignBody = Components.Schemas.DesignBody;
export type Source = Components.Schemas.Source;
export type Feed = Components.Schemas.Feed;
export type FeedItem = Components.Schemas.FeedItem;

export class Projects {
  static printExpired(dt: Date) {
    return Date.now() - dt.getTime() > 5 * 60 * 1000.0;
  }

  static isPrinting(project: Project | null) {
    return (
      (project != null &&
        project.print_started != null &&
        project.print_ended == null &&
        !Projects.printExpired(new Date(project.print_started))) ||
      false
    );
  }

  static udate(project: Project | null, pages: Page[]): string {
    return pages.reduce(
      (acc, page) => (acc > page.udate ? acc : page.udate),
      (project && project.udate) || ""
    );
  }
}

export interface State {
  session: Session | null;
  teams: Team[];
  user: User | null;
  notifications: { [key: string]: number };
  workspaces: Workspace[];
  workspace: Workspace | null;
  projects: Project[];
  project: Project | null;
  pages: Page[];
  pageId: string | null;
  shares: Share[];
  memberships: Membership[];
  designs: Design[];
  refreshing: boolean;
  firstClipFlag: boolean;
  feeds: Feed[];
  feedItems: FeedItem[];
}

export namespace State {
  export const getTeam = (s: State): Team | null =>
    s.teams.find((t) => t.id === s.session?.team_id) || null;

  export const withCfc = (s: State): boolean =>
    getTeam(s)?.cfc_enabled === true;
}

export interface LoggedState extends State {
  session: Session;
  user: User;
}

/** Dispatchable actions our reducer will handle. */
export type Action =
  | { type: "INIT" }
  | { type: "SET_SESSION"; session: Session }
  | { type: "SET_TEAMS"; teams: Team[] }
  | { type: "UPD_TEAM"; team: Team }
  | { type: "SET_USER"; user: User }
  | { type: "SIGNOUT" }
  // workspaces
  | { type: "SET_WORKSPACES"; workspaces: Workspace[] }
  | { type: "SET_WORKSPACE"; workspace: Workspace; projects?: Project[] }
  | { type: "ADD_WORKSPACE"; workspace: Workspace }
  | { type: "DEL_WORKSPACE"; id: string }
  | { type: "EXIT_WORKSPACE" }
  // projects
  | { type: "SET_PROJECTS"; projects: Project[] }
  | { type: "SET_PROJECT"; project: Project; pages?: Page[] }
  | { type: "ADD_PROJECT"; project: Project }
  | { type: "DEL_PROJECT"; id: string }
  | { type: "UPD_PROJECT"; project: Project }
  | { type: "EXIT_PROJECT" }
  // pages
  | { type: "SET_PAGES"; pages: Page[] }
  | { type: "ADD_PAGE"; page: Page }
  | { type: "DEL_PAGE"; pageId: string }
  | { type: "UPD_PAGE"; page: Page }
  | { type: "UPD_PAGE_STATS"; pageId: string; stats: PageStat[] }
  | { type: "SET_PAGE"; page: Page | null }
  // shares
  | { type: "SET_SHARES"; shares: Share[] }
  | { type: "ADD_SHARE"; share: Share }
  | { type: "DEL_SHARE"; id: string }
  // members
  | { type: "SET_MEMBERSHIPS"; memberships: Membership[] }
  | { type: "ADD_MEMBERSHIP"; membership: Membership }
  | { type: "DEL_MEMBERSHIP"; user_id: string }
  | { type: "UPD_MEMBERSHIP"; membership: Membership }
  // flag
  | { type: "SET_REFRESHING"; value: boolean }
  | { type: "SET_FIRST_CLIP_FLAG"; value: boolean }
  // designs
  | { type: "SET_DESIGNS"; designs: Design[] }
  | { type: "ADD_DESIGN"; design: Design }
  | { type: "UPD_DESIGN"; design: Design }
  | { type: "DEL_DESIGN"; design_id: string }
  // feeds
  | { type: "SET_FEEDS"; feeds: Feed[] }
  | { type: "ADD_FEED"; feed: Feed }
  | { type: "DEL_FEED"; feed_id: string }
  | {
      type: "SET_FEED_ITEMS";
      items: FeedItem[];
      offset: number;
      limit: number;
    }
  | {
      type: "UPD_FEED_ITEM";
      item: FeedItem;
    }
  | {
      type: "DEL_FEED_ITEM";
      id: string;
    }
  | {
      type: "SET_NOTIFICATIONS";
      notifications: { [key: string]: number };
    };
