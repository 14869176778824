import React from "react";
import { useAppState, useDispatch } from "./hooks/useAppState";
import { Router, Redirect } from "@reach/router";
import { Signin } from "./pages/Signin";
import { Signup } from "./pages/Signup";
import { ForgotPassword } from "./pages/auth/ForgotPassword";
import { ResetPassword } from "./pages/auth/ResetPassword";
import { AdminDashboard } from "./pages/admin/AdminDashboard";
import { AdminSystemStatus } from "./pages/admin/AdminSystemStatus";
import { SharedAccess } from "./pages/SharedAccess";
import { Projects } from "./pages/Projects";
import { ProjectPreview } from "./pages/ProjectPreview";
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy";
import { Plans } from "./pages/Plans";
import { ProjectClips } from "./pages/ProjectClips";
import { Feeds } from "./pages/Feeds";
import * as actions from "./store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "easymde/dist/easymde.min.css";

// font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faFileExport,
  faRecycle,
  faDownload,
  faTrashAlt,
  faExternalLinkAlt,
  faChevronRight,
  faChevronDown,
  faFilePdf,
  faShare,
  faEnvelope,
  faEye,
  faEdit,
  faBars,
  faGripVertical,
  faLink,
  faTrash,
  faBan,
  faPaintBrush,
  faUsers,
  faRss,
} from "@fortawesome/free-solid-svg-icons";
import { ExtendTrial } from "./pages/ExtendTrial";
import { Workspaces } from "./pages/Workspaces";
import { OauthCallback } from "./pages/OauthCallback";

library.add(
  faCheckSquare,
  faBars,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faFileExport,
  faRecycle,
  faDownload,
  faTrashAlt,
  faExternalLinkAlt,
  faChevronRight,
  faChevronDown,
  faFilePdf,
  faShare,
  faEnvelope,
  faEye,
  faEdit,
  faGripVertical,
  faLink,
  faTrash,
  faBan,
  faPaintBrush,
  faUsers,
  faRss
);

export const ScrollToTop = ({ children, location }: any) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

const App: React.FC = () => {
  const [ready, setReady] = React.useState(false);
  const { user, session } = useAppState((s) => ({
    user: s.user,
    session: s.session,
  }));
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.init()).then(() => setReady(true));
  }, [dispatch]);
  return (
    <div className="App">
      {!ready ? (
        // empty loading state while initialing state
        <div className="text-center mt-5 pt-5">
          <div>
            <img
              alt="Reach Report logo"
              src="/reachreport_text.svg"
              width="200"
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div>Loading...</div>
        </div>
      ) : user == null ? (
        <>
          <Router primary={false}>
            <ScrollToTop path="/">
              <Signin path="/" />
              <Signin path="/signin" />
              <Signup path="/signup" />
              <Terms path="/terms" />
              <Privacy path="/privacy" />
              <ForgotPassword path="/auth/forgot" />
              <ResetPassword path="/auth/reset/:token" />
              <OauthCallback path="/oauth/:service/callback" />
              <SharedAccess path="/p/:projectId/:shareId" />
              <ExtendTrial path="/extend-trial/:userId/:teamId/:code" />
              <Redirect from="*" to="/" default noThrow />
            </ScrollToTop>
          </Router>
        </>
      ) : (
        <Router primary={false}>
          <ScrollToTop path="/">
            <Terms path="/terms" />
            <Privacy path="/privacy" />
            <Workspaces path="/workspaces" />
            <Feeds path="/workspaces/:workspaceId/feeds" />
            <Projects path="/workspaces/:workspaceId/" />
            <Plans path="/plans" />
            <ProjectPreview path="/projects/:id/preview" />
            <ProjectClips path="/projects/:id/clips" />
            {session?.admin && <AdminDashboard path="/admin" />}
            {session?.admin && <AdminSystemStatus path="/admin/status" />}
            <SharedAccess path="/p/:projectId/:shareId" />
            <Redirect from="/projects/:id/" to="/projects/:id/clips" />
            <ExtendTrial path="/extend-trial/:userId/:teamId/:code" />
            <Redirect from="*" to="/workspaces" default noThrow />
          </ScrollToTop>
        </Router>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
