import * as React from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { CardPage, Label, Lead } from "../components/CardPage";
import { ApiError } from "../api";
import { useDispatch } from "../hooks/useAppState";
import classnames from "classnames";
import * as actions from "../store/actions";
import useI18n from "../hooks/useI18n";
import { navLang } from "../utils/i18n";
import GoogleButton from "../components/auth/GoogleButton";
import { getAuthUrl } from "../utils/oauth/gogole";
import { getCookie } from "../utils/cookies";

const texts = {
  errors: {
    "duplicate-email": {
      en: (
        <>
          This email already exists,{" "}
          <a href="/auth/forgot">forgot your password?</a>
        </>
      ),
      fr: (
        <>
          Cet email existe déjà,{" "}
          <a href="/auth/forgot">vous avez oublié votre mot de passe ?</a>
        </>
      ),
    },
  },
} as const;

export function Signup(_props: RouteComponentProps) {
  const t = useI18n();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    lang: navLang,
    name: "",
    email: "",
    password: "",
    accept: false,
    loading: false,
    success: false,
    error: null as ApiError | null,
    referer_code: getCookie("ref") || "",
    src_landing_url: getCookie("src_landing_url") || "",
    src_referer_url: getCookie("src_referer_url") || "",
  });
  const disabled = state.loading || state.success;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = e.target.getAttribute("name")!;
    if (name === "accept") {
      setState({ ...state, accept: e.target.checked });
    } else {
      const value: string = e.target.value;
      setState({ ...state, [name as any]: value });
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    setState({ ...state, error: null, loading: true });
    dispatch(actions.signup(state))
      .then(() => {
        (window as any).gtag_report_conversion &&
          (window as any).gtag_report_conversion();
        (window as any).plausible && (window as any).plausible("Signup");
      })
      .then(() => navigate("/"))
      .catch((e: any) => {
        console.log(e.response);
        const error = (e.response && e.response.data) || null;
        setState({ ...state, loading: false, error });
      });
  };
  const errors = (state.error && state.error.fields) || {};
  const errorMessage = (field: string) => {
    const message = (errors[field] && errors[field].message) || "";
    return texts.errors[message as any as keyof typeof texts["errors"]]
      ? t(texts.errors[message as any as keyof typeof texts["errors"]])
      : message;
  };
  const onOauth = (provider: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    const redirect = `${process.env.REACT_APP_URL}/oauth/${provider}/callback`;
    const state = {
      redirect,
    };
    const url = getAuthUrl(redirect, JSON.stringify(state));
    window.location.href = url.toString();
  };
  return (
    <CardPage
      //orange
      coloredBox={t({
        en: (
          <>
            <h1>
              Start Your{" "}
              <span className="d-block d-md-inline">Free&nbsp;Trial.</span>
            </h1>
            <br />
            <Lead>Send Coverage Reports Your Customers Love.</Lead>
          </>
        ),
        fr: (
          <>
            <h1>Votre Essai&nbsp;Gratuit.</h1>
            <br />
            <Lead>Vos clients adoreront vos rapports RP.</Lead>
          </>
        ),
      })}
      topnav={
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <a href="https://www.reachreport.io">
              <img src="/back.svg" alt="back" />
            </a>
          </p>
          <p className="rr-t3 rr-mid-grey text-right">
            <span>
              {t({
                en: "Already have an account?",
                fr: "Vous avez déjà un compte ?",
              })}
            </span>
            <span className="d-block d-md-inline">
              <Link to="/signin">
                <span className="ml-1 px-1 btn btn-outline-primary btn-sm">
                  {t({ en: "Sign in", fr: "Connectez-vous" })}
                </span>
              </Link>
            </span>
          </p>
        </div>
      }
    >
      <img
        height="44"
        src="/reachreport_text.svg"
        //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
        alt="ReachReport.io"
        className="d-none d-md-inline-block"
      />
      <form onSubmit={handleSubmit} className="text-left mt-3 pb-5">
        <div className="form-group">
          <Label>{t({ en: "Your name:", fr: "Votre nom :" })}</Label>
          <input
            className={classnames("form-control", {
              "is-invalid": !!errors["input.name"],
            })}
            type="text"
            name="name"
            value={state.name}
            onChange={handleChange}
            disabled={disabled}
            placeholder={t({ en: "John Clark", fr: "Louise Leroux" })}
          />
          <div className="invalid-feedback">{errorMessage("input.email")}</div>
        </div>
        <div className="form-group">
          <Label>{t({ en: "Email:", fr: "Email :" })}</Label>
          <input
            className={classnames("form-control", {
              "is-invalid": !!errors["input.email"],
            })}
            type="text"
            name="email"
            placeholder={t({ en: "john@clark.com", fr: "louise@leroux.fr" })}
            value={state.email}
            onChange={handleChange}
            disabled={disabled}
          />
          <div className="invalid-feedback">{errorMessage("input.email")}</div>
        </div>
        <div className="form-group">
          <Label>{t({ en: "Password:", fr: "Mot de passe :" })}</Label>
          <input
            className={classnames("form-control", {
              "is-invalid": !!errors["input.password"],
            })}
            type="password"
            name="password"
            value={state.password}
            onChange={handleChange}
            disabled={disabled}
          />
          <div className="invalid-feedback">
            {errorMessage("input.password")}
          </div>
        </div>
        <div className="form-check">
          <input
            required
            name="accept"
            id="accept"
            className="form-check-input"
            type="checkbox"
            disabled={disabled}
            checked={state.accept}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="accept">
            {t({
              en: (
                <>
                  I accept the{" "}
                  <a href="/terms" target="_blank">
                    Terms of service
                  </a>{" "}
                  and{" "}
                  <a href="/privacy" target="_blank">
                    Privacy policy
                  </a>
                  .
                </>
              ),
              fr: (
                <>
                  J'accepte les{" "}
                  <a href="/terms" target="_blank">
                    Conditions d'utilisation
                  </a>{" "}
                  et{" "}
                  <a href="/privacy" target="_blank">
                    la politique de confidentialité
                  </a>
                  .
                </>
              ),
            })}
          </label>
        </div>
        <div className="text-center py-2 mt-2">
          <button
            type="submit"
            className="btn btn-primary px-5"
            disabled={disabled}
          >
            {t({ en: "Register", fr: "Créer mon compte" })}
          </button>
        </div>
        <div className="text-center mt-4">
          <hr className="mb-n1" />
          <div className="mt-n3 pt-1">
            <div className="mt-n2 pb-3 px-2 bg-white d-inline-block mx-auto">
              {t({ en: "or", fr: "ou" })}
            </div>
          </div>
          <GoogleButton
            label={t({
              en: "Sign up with Google",
              fr: "Inscrivez-vous avec Google",
            })}
            signin={onOauth}
          />
        </div>
      </form>
      <div className="text-center pb-5 d-md-none">
        <img
          height="32"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
        />
      </div>
    </CardPage>
  );
}
