const nFormat = new Intl.NumberFormat();
//const number = (v: any) => nFormat.format(parseFloat(v) || 0);

export const numberF = (v: any, sep = "") => {
  const n = Math.round(parseFloat((v || 0).toString().replace(/,/g, "")));
  if (n < 1000) return n.toString();
  if (n < 100000) return nFormat.format(Math.round(n / 100) / 10) + sep + "K";
  if (n < 1000000) return nFormat.format(Math.round(n / 100) / 10) + sep + "K";
  if (n < 1000000000)
    return nFormat.format(Math.round(n / 100000) / 10) + sep + "M";
  return nFormat.format(Math.round(n / 100000000) / 10) + sep + "B";
};
