import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook
} from "react-redux";
import { State, LoggedState, Action } from "../store/types";
import { ThunkDispatch } from "redux-thunk";
import { Effect } from "../store/actions";

export const useAppState: TypedUseSelectorHook<State> = useReduxSelector;
export const useLoggedState: TypedUseSelectorHook<LoggedState> = useReduxSelector;
export const useDispatch: <R>() => ThunkDispatch<
  State,
  Effect<R>,
  Action
> = useReduxDispatch;
