import * as React from "react";

export const usePromise = <T>(
  p: () => Promise<T>
): {
  data: T | null;
  loading: boolean;
  error: null | any;
  set: (data: T | null) => void;
} => {
  const ref = React.useRef(p);
  const [state, setState] = React.useState({
    data: null as T | null,
    loading: false,
    error: null,
  });
  React.useEffect(() => {
    setState((s) => ({ ...s, loading: true }));
    ref
      .current()
      .then((data) => setState((s) => ({ ...s, loading: false, data })))
      .catch((error) => setState((s) => ({ ...s, loading: false, error })));
  }, []);
  return {
    ...state,
    set: (data: T | null) => setState((s) => ({ ...s, data })),
  };
};
