import React from "react";
import cls from "classnames";
import theme from "../theme";
import useI18n from "../hooks/useI18n";
import classNames from "classnames";

export default function Faq() {
  const t = useI18n();
  return (
    <div className="p-4 mx-0">
      <h1 className="rr-h2b mb-3">{t({ en: "FAQs", fr: "FAQ" })}</h1>
      {t({
        en: () => (
          <>
            <FaqItem title={<>Can I change plans anytime?</>} first>
              <p>
                Yes, you can change plans or cancel anytime. Plans renew every
                month. If you cancel before your plan renews you will no longer
                be charged.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>My clip volume fluctuates, which plan should I contract?</>
              }
            >
              <p>
                We recommend contracting the smallest suitable tier. You can
                then upgrade when you hit the plan limit. If your volume
                decreases again in the next month you can downgrade your plan.
                This way your subscription will match your clip volume even if
                it fluctuates.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>What happens if I exceed the number of clips in my plan?</>
              }
            >
              <p>
                As you approach the clip limit for your plan, we will notify you
                that you’ve reached 90% and 100% of your limit. These alerts
                give you time to upgrade your subscription to the next tier.
              </p>
            </FaqItem>
            <FaqItem title={<>Do all plans include all features?</>}>
              <p>
                All plans include all features. You get unlimited reports,
                clients &amp; users.
              </p>
            </FaqItem>
            <FaqItem title={<>Are there any additional fees?</>}>
              <p>None, you only pay for the clips.</p>
            </FaqItem>
            <FaqItem
              open
              title={<>Any other questions that have not been answered?</>}
            >
              <p>
                <a
                  href="mailto:support@reachreport.io"
                  style={{ textDecoration: "" }}
                >
                  Contact us!{" "}
                </a>
              </p>
            </FaqItem>
          </>
        ),
        fr: () => (
          <>
            <FaqItem
              first
              title={
                <>
                  Est-ce que je peux changer de formule à n'importe quel
                  moment ?
                </>
              }
            >
              <p>
                Oui, vous pouvez changer ou résilier votre formule à n'importe
                quel moment. Les abonnements sont renouvelés chaque mois.
                Résiliez avant le prélèvement pour ne pas être facturé un mois
                de plus.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>
                  Mon volume de coupures est variable, quelle formule dois-je
                  choisir ?
                </>
              }
            >
              <p>
                Nous recommandons de choisir la formule la plus petite pour vos
                besoins réguliers. Vous pourrez toujours opter pour une formule
                suppérieure lorsque vous atteindrez une limite. Si votre volume
                décroit le mois suivant revenez simplement à la formule
                minimale. De cette manière votre abonnement correspondra
                toujours à votre volume d'activité.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>
                  Que se passe-t-il si je dépasse le nombre de coupures
                  autorisées par ma formule ?
                </>
              }
            >
              <p>
                Nous vous enverrons un mail pour vous informer lorsque vous
                aurez atteint 90% et 100% du nombre de coupures de votre
                formule. Ces alertes vous donneront le temps de souscrire à la
                formule suppérieure.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>
                  Est-ce que toutes les formules incluent toutes les
                  fonctionnalités ?
                </>
              }
            >
              <p>
                Oui, toutes les formules offrent toutes les fonctionnalités. De
                plus vous pouvez avoir un nombre illimité de clients, rapports
                et utilisateurs.
              </p>
            </FaqItem>
            <FaqItem title={<>Y-a-t-il des frais cachés ?</>}>
              <p>Aucun, vous payez simplement pour les coupures.</p>
            </FaqItem>
            <FaqItem
              open
              title={
                <>
                  Vous avez une autre question à laquelle nous n'avons pas
                  répondu ?
                </>
              }
            >
              <p>
                <a
                  href="mailto:support@reachreport.io"
                  style={{ textDecoration: "" }}
                >
                  Contactez-nous !
                </a>
              </p>
            </FaqItem>
          </>
        ),
        es: () => (
          <>
            <FaqItem title={<>Can I change plans anytime?</>}>
              <p>
                Yes, you can change plans or cancel anytime. Plans renew every
                month. If you cancel before your plan renews you will no longer
                be charged.
              </p>
            </FaqItem>
            <FaqItem
              first
              title={
                <>My clip volume fluctuates, which plan should I contract?</>
              }
            >
              <p>
                We recommend contracting the smallest suitable tier. You can
                then upgrade when you hit the plan limit. If your volume
                decreases again in the next month you can downgrade your plan.
                This way your subscription will match your clip volume even if
                it fluctuates.
              </p>
            </FaqItem>
            <FaqItem
              title={
                <>What happens if I exceed the number of clips in my plan?</>
              }
            >
              <p>
                As you approach the clip limit for your plan, we will notify you
                that you’ve reached 90% and 100% of your limit. These alerts
                give you time to upgrade your subscription to the next tier.
              </p>
            </FaqItem>
            <FaqItem title={<>Do all plans include all features?</>}>
              <p>
                All plans include all features. You get unlimited reports,
                clients &amp; users.
              </p>
            </FaqItem>
            <FaqItem title={<>Are there any additional fees?</>}>
              <p>None, you only pay for the clips.</p>
            </FaqItem>
            <FaqItem
              open
              title={<>Any other questions that have not been answered?</>}
            >
              <p>
                <a
                  href="mailto:support@reachreport.io"
                  style={{ textDecoration: "" }}
                >
                  Contact us!{" "}
                </a>
              </p>
            </FaqItem>
          </>
        ),
      })()}
      <style>
        {`
          .faq-item a {
            color: ${theme.blue};
            border-bottom: 1px solid ${theme.blue};
          }
          .faq-item a:hover {
            text-decoration: none;
            color: ${theme.white} !important;
            background-color: ${theme.blue};
          }
          .faq h1 {
            font-weight: normal;
            font-size: 36px;
            line-height: 49px;
            text-align: center;
            margin-bottom: 28px;
            margin-top: 0px;
            padding-top: 60px;
          }
          .faq {
            max-width: 780px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 80px;
            margin-top: 0px;
          }
        `}
      </style>
    </div>
  );
}

function FaqItem({
  open,
  title,
  children,
  first = false,
}: {
  open?: boolean;
  title: any;
  children: any;
  first?: boolean;
}) {
  const [collapse, setCollapse] = React.useState(!open);
  const handleToggle = (e: any) => {
    e.preventDefault();
    setCollapse(!collapse);
  };
  return (
    <div className={classNames("faq-item", { first })}>
      <h5
        onClick={handleToggle}
        className="cursor-pointer d-flex rr-t3b my-0 py-4"
      >
        <span className="flex-grow-1">{title}</span>
        <span>
          <img
            width="18"
            src={!collapse ? "/up.svg" : "/down.svg"}
            alt="down"
          />
        </span>
      </h5>
      <div className="content-container">
        <div className={cls("content", { closed: collapse })}>{children}</div>
      </div>
      <style>{`
        .faq-item {
          border-bottom: 0.5px solid ${theme.midGrey};
        }        
        .faq-item .content-container {
          position: relative;
          overflow: hidden;
          margin-top: 0px;
          padding-top: 0px;
        }
        .faq-item .content {
          text-align: justify;
          margin-top: 0px;
          padding-bottom: 8px;
          opacity: 1;
          transition: margin-top 250ms, opacity 250ms;
          font-size: 14px;
          margin-right: 32px;          
        }
        .faq-item .closed {
          opacity: 0;
          margin-top: -100%;
        }
        .faq-item.first {
          border-top: 0.5px solid ${theme.midGrey};
        }
      `}</style>
    </div>
  );
}
