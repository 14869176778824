import { loadSession, signout } from "../store/actions";
import { api } from "../api";
import { useDispatch } from "./useAppState";

export const useConnectAsUser = () => {
  const dispatch = useDispatch();
  const doLoginAs = (userId: string) => {
    const load = async () => {
      const { data: session } = await api.ConnectAsUser({ userId: userId });
      await dispatch(signout());
      await dispatch(loadSession(session.token));
    };
    load().catch((e) => console.log(e));
  };
  return doLoginAs;
};

export default useConnectAsUser;
