import * as React from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import classnames from "classnames";
import { CardPage, Label } from "../../components/CardPage";
import { api, ApiError, mkFieldsError } from "../../api";
import { Components } from "../../client";
import * as actions from "../../store/actions";
import { useDispatch } from "../../hooks/useAppState";
import useI18n, { useLang } from "../../hooks/useI18n";
import appTexts from "../../texts";

export function ResetPassword(props: RouteComponentProps<{ token: string }>) {
  const t = useI18n();
  const [state, setState] = React.useState(
    "loading" as "loading" | "not-found" | "ready"
  );
  const [reset, setReset] = React.useState(
    null as Components.Schemas.Reset | null
  );
  React.useEffect(() => {
    if (props.token) {
      api
        .GetResetToken(props.token)
        .then((res) => {
          setState("ready");
          setReset(res.data);
        })
        .catch(() => setState("not-found"));
    }
  }, [props.token]);
  return (
    <CardPage
      topnav={
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <a href="https://www.reachreport.io">
              <img src="/back.svg" alt="back" />
            </a>
          </p>
          <p className="rr-t3 rr-mid-grey text-right">
            {t({
              en: <span>Don't have an account yet?</span>,
              fr: <span>Vous n'avez pas encore de compte ?</span>,
            })}
            <span className="d-block d-md-inline">
              <Link to="/signup">
                <span className="ml-1 px-1 btn btn-outline-primary btn-sm">
                  {t({ fr: "Inscrivez-vous", en: "Register Now" })}
                </span>
              </Link>
            </span>
          </p>
        </div>
      }
      coloredBox={t({
        en: <h1>Reset password</h1>,
        fr: <h1>Changement de mot de passe</h1>,
      })}
    >
      <div className="mb-3">
        <img
          height="44"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
          className="d-none d-md-inline-block"
        />
      </div>
      {reset ? (
        <ResetForm reset={reset} />
      ) : state === "loading" ? (
        <p className="lead">{t(appTexts.loading)}</p>
      ) : state === "not-found" ? (
        <>
          <p className="lead">
            {t({
              en: "Reset link expired",
              fr: "Le lien de changement de mot de passe a expiré.",
            })}
          </p>
          <p>
            <Link to="/auth/forgot">
              <a href="/auth/forgot">
                {t({
                  en: "Send me another link",
                  fr: "Envoyez-moi un nouveau lien",
                })}
              </a>
            </Link>
          </p>
        </>
      ) : (
        <></>
      )}
      <div className="text-center pb-5 d-md-none">
        <img
          height="32"
          src="/reachreport_text.svg"
          //src="https://reachreport.io/wp-content/uploads/2020/02/reachreport_logo.jpg"
          alt="ReachReport.io"
        />
      </div>
    </CardPage>
  );
}

function ResetForm({ reset }: { reset: Components.Schemas.Reset }) {
  const t = useI18n();
  const lang = useLang();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(
    null as { email: string; password: string } | null
  );
  const [error, setError] = React.useState(null as ApiError | null);
  const [form, setForm] = React.useState({
    password: "",
    passwordConfirm: "",
  });
  const checkForm = () => {
    if (form.password.trim().length < 6) {
      setError(
        mkFieldsError({
          "input.password": {
            message: t({
              en: "Minimum 6 characters required.",
              fr: "6 caractères minimum requis.",
            }),
          },
        })
      );
      return false;
    }
    if (form.passwordConfirm !== form.password) {
      setError(
        mkFieldsError({
          "input.passwordConfirm": {
            message: t({
              en: "Confirmation must match new password.",
              fr: "La confirmation doit être identique au mot de passe.",
            }),
          },
        })
      );
      return false;
    }
    return true;
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading || !checkForm()) {
      return;
    }
    setError(null);
    setLoading(true);
    api
      .UseResetToken(reset.token, { password: form.password })
      .then((res) => setSuccess(res.data))
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({
      id: e.target.id,
    });
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };
  const handleSignin = (e: React.MouseEvent) => {
    e.preventDefault();
    if (loading || !success) {
      return;
    }
    setLoading(true);
    dispatch(
      actions.signin({ login: success.email, password: success.password, lang })
    )
      .then(() => navigate("/"))
      .catch((e) => {
        console.log(e);
      });
  };
  const errors = (error && error.fields) || {};
  const errorMessage = (field: string) =>
    (errors[field] && errors[field].message) || "";
  return success ? (
    <>
      <p className="lead">
        {t({
          en: "New password saved!",
          fr: "Nouveau mot de passe enregistré !",
        })}
      </p>
      <p>
        <a href="/signin" className="btn btn-primary" onClick={handleSignin}>
          {t({ en: "Sign me in", fr: "Se connecter" })}
        </a>
      </p>
    </>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <p className="lead">
          {t({
            en: <>Enter new password for {reset.email}:</>,
            fr: <>Entrez le nouveau mot de passe pour {reset.email} :</>,
          })}
        </p>
        <input type="hidden" name="login" value={reset.email} />
        <div className="form-group">
          <Label htmlFor="password">
            {t({ en: "New password", fr: "Nouveau mot de passe" })}
          </Label>
          <input
            id="password"
            type="password"
            className={classnames("form-control", {
              "is-invalid": !!errors["input.password"],
            })}
            onChange={handleChange}
            value={form.password}
            required
          />
          <div className="invalid-feedback">
            {errorMessage("input.password")}
          </div>
        </div>
        <div className="form-group">
          <Label htmlFor="passwordConfirm">
            {t({ en: "Confirm password", fr: "Confirmation du mot de passe" })}
          </Label>
          <input
            id="passwordConfirm"
            type="password"
            className={classnames("form-control", {
              "is-invalid": !!errors["input.passwordConfirm"],
            })}
            onChange={handleChange}
            value={form.passwordConfirm}
            required
          />
          <div className="invalid-feedback">
            {errorMessage("input.passwordConfirm")}
          </div>
        </div>
        <div>
          <button className="btn btn-primary px-5">
            {t({ en: "Change", fr: "Changer" })}
          </button>
        </div>
      </form>
    </>
  );
}
