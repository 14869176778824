import React, { useEffect, useState } from "react";
import {
  Award,
  ChevronDown,
  LogOut,
  Menu,
  Paperclip,
  Plus,
  Settings,
  X,
} from "react-feather";
import theme from "../../theme";
import * as actions from "../../store/actions";
import styled from "styled-components";
import WorkspacesTree from "./WorkspacesTree";
import { useLocation, useNavigate } from "@reach/router";
import { Users } from "react-feather";
import useI18n from "../../hooks/useI18n";
import CreateReportDropdown from "./CreateReportDropdown";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import { DrawerState, useAppDrawer } from "../../hooks/useAppDrawer";
import { State } from "../../store/types";
import appTexts from "../../texts";

export default function LeftMenu({
  onCreate,
  home = "/",
}: {
  onCreate?: () => Promise<void>;
  home?: string;
}) {
  const t = useI18n();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [createReportDropdown, setCreateReportDropdown] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [location]);
  return (
    <Nav open={open}>
      <NavLogo>
        <a
          className=""
          href={home}
          style={{
            paddingTop: "21px",
            paddingBottom: "21px",
            height: "70px",
          }}
        >
          <img height="28" src="/reachreport_text.svg" alt="ReachReport.io" />
        </a>
        <button
          className="d-md-none bg-none border-0 px-3 rr-mid-grey"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          {open ? <X width={24} /> : <Menu width={24} />}
        </button>
      </NavLogo>
      <NavContent open={open}>
        <div className="flex-shrink-0 w-100">
          <CreateReportBtn
            // data-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.preventDefault();
              setCreateReportDropdown(!createReportDropdown);
            }}
          >
            <Plus height="18" className="mr-1" />
            <div>{t(appTexts.newReport)}</div>
          </CreateReportBtn>
          {createReportDropdown && (
            <CreateReportDropdown
              close={() => setCreateReportDropdown(false)}
            />
          )}
        </div>

        <div
          className="flex-grow-1 w-100 mt-3 mt-md-0"
          style={{ overflowY: "auto", overflowX: "clip" }}
        >
          <WorkspacesTree />
        </div>

        <div className="flex-shrink-0 px-4 w-100">
          <GeneralMenu />
        </div>

        <div className="flex-shrink-0 px-4 w-100 mb-4 mt-2">
          <AccountMenu />
        </div>
      </NavContent>
    </Nav>
  );
}

/** List of general menu links */
const GeneralMenu = () => {
  const t = useI18n();
  const navigate = useNavigate();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);
  const team = useAppState(State.getTeam);
  return (
    <>
      {team?.cfc_enabled && (
        <MenuButton
          onClick={(e) => {
            e.preventDefault();
            openDrawer("cfc");
          }}
        >
          <Paperclip height={16} width={24} />
          CFC
        </MenuButton>
      )}
      <MenuButton
        onClick={(e) => {
          e.preventDefault();
          openDrawer("teams");
        }}
      >
        <Users height={16} width={24} />
        {t(appTexts.team)}
      </MenuButton>
      <MenuButton
        onClick={(e) => {
          e.preventDefault();
          // openDrawer("subscriptions");
          navigate("/plans");
        }}
      >
        <Award height={16} width={24} />
        {t(appTexts.subscription)}
      </MenuButton>
      <MenuButton
        onClick={(e) => {
          e.preventDefault();
          openDrawer("settings");
        }}
      >
        <Settings height={16} width={24} />
        {t(appTexts.accountSettings)}
      </MenuButton>
    </>
  );
};

/** Dropdown menu displaying current account/team and giving the user the opportunity to switch/logout. */
const AccountMenu = () => {
  const t = useI18n();
  const session = useAppState((s: State) => s.session);
  const user = useAppState((s: State) => s.user);
  const team = useAppState(State.getTeam);
  const teams = useAppState((s) => s.teams);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="btn-group dropup w-100">
      <AccountButton
        className="d-flex align-items-center justify-content-between w-100 p-2"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <div>
          <div className="rr-t4 rr-dark-grey">{team?.name}</div>
          <div className="rr-t5 rr-mid-grey">{user?.email || "Email"}</div>
        </div>
        <ChevronDown height={12} className="rr-mid-grey" />
      </AccountButton>
      <div className="dropdown-menu dropdown-menu-right w-100">
        {teams.map((m) => (
          <a
            key={m.id}
            className="dropdown-item overflow-hidden d-flex align-items-center"
            href="#-"
            onClick={(e) => {
              e.preventDefault();
              dispatch({ type: "EXIT_WORKSPACE" });
              dispatch({ type: "EXIT_PROJECT" });
              dispatch(actions.selectTeam(m.id)).then(() => navigate("/"));
            }}
          >
            <Users height={16} className="mr-2" />
            <div>
              {m.name || m.id}
              <span className="d-block text-muted rr-t5">{m.role}</span>
            </div>
          </a>
        ))}
        {session?.admin && (
          <a href="/admin" className="dropdown-item rr-t4 text-danger">
            <Settings height={16} className="mr-2" />
            Admin
          </a>
        )}
        <a
          href="/"
          className="dropdown-item rr-t4 rr-blue text-primary"
          onClick={(e) => {
            e.preventDefault();
            dispatch(actions.signout());
          }}
        >
          <LogOut height={16} className="mr-2" />
          {t({ en: "Sign out", fr: "Déconnexion" })}
        </a>
      </div>
    </div>
  );
};

const Nav = styled.nav.attrs(() => ({
  className: "d-flex flex-column justify-content-start align-items-start",
}))<{ open: boolean }>`
  background-color: ${theme.lighterGrey};
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1040;

  ${(props) => (props.open ? "bottom: 0px;" : "bottom: auto;")}

  @media (min-width: 768px) {
    width: 252px;
    right: auto;
    bottom: 0px;
  }
`;

const NavLogo = styled.div`
  background-color: ${theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 28px;
  height: 64px;
  width: 100%;
  overflow-x: hidden;

  // doesn't change
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: 768px) {
    height: 70px;
    width: 252px;
  }
`;

const NavContent = styled.div.attrs(({ open }: { open: boolean }) => ({
  className:
    (open ? "d-flex" : "d-none") +
    " " +
    "flex-shrink-1 flex-grow-1 flex-column-reverse flex-md-column justify-content-middle align-items-start w-100 h-100",
}))<{ open: boolean }>`
  overflow-y: hidden;
  overflow-x: hidden;
  @media (min-width: 768px) {
    display: flex !important;
  }
`;

const CreateReportBtn = styled.button.attrs(() => ({
  className: "rr-cta d-flex align-items-center",
}))`
  border: 0px;
  background-color: white;
  padding: 14px 10px;
  color: ${theme.blue};

  width: 100% !important;
  border-top: 1px solid ${theme.lightGrey};

  @media (min-width: 768px) {
    width: 220px;
    margin: 0px 10px 0px 10px;
    background-color: transparent;
    border-top: none;
  }

  &:active {
    outline: 0px !important;
  }
`;

const MenuButton = styled.a.attrs((props) => ({
  ...props,
  className: "d-block rr-dark-grey rr-t3 py-1 mb-1",
}))`
  cursor: pointer;
  svg {
    padding-right: 8px;
    color: ${theme.grey};
  }
  &:hover svg {
    color: ${theme.blue};
  }
`;

const AccountButton = styled.button`
  background-color: ${theme.white};
  border: 0px solid ${theme.white};
  display: d-flex;
  width: 100%;
  text-align: left;
  margin-top: 32px;
  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;
