import _ from "lodash";
import zustand from "zustand";
import { useEffect } from "react";

export type Drawer =
  | "settings"
  | "subscriptions"
  | "teams"
  | "cfc"
  | "design_library"
  | { project_design: string; focus?: "title" }
  | { project_share: string }
  | { clip_edit: string }
  | { admin_user: string }
  | { feed_item: string };

export interface DrawerState {
  stack: Drawer[];
  open(slide: Drawer): void;
  close(): void;
}

export const useAppDrawer = zustand<DrawerState>((set, get) => ({
  stack: [] as Drawer[],
  open: (v: Drawer) => {
    const stack = get().stack;
    for (let drawer of stack) {
      if (_.isEqual(drawer, v)) {
        return; // already opened
      }
    }
    set({ stack: stack.concat([v]) });
  },
  close: () => {
    set({ stack: get().stack.slice(0, -1) });
  },
}));

export const useDrawerBackdrop = () =>
  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
      const bg = document.body.querySelector(".modal-backdrop");
      if (bg) {
        bg.remove();
      }
    };
  }, []);
