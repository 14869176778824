import React from "react";
import { MoreVertical } from "react-feather";
import theme from "../../theme";
import { ReportDropdown, useReportDropdown } from "./ReportDropdown";
import { format } from "date-fns";
import { Project, getProjectTitle } from "../../model";
import { Link, useNavigate } from "@reach/router";
import styled from "styled-components";
import useI18n from "../../hooks/useI18n";
import texts from "../../texts";

export default function ReportCard({ report }: { report: Project }) {
  const t = useI18n();
  const dropdown = useReportDropdown();
  const navigate = useNavigate();
  const url = `/projects/${report.id}/clips`;
  const open = () => navigate(url);
  return (
    <div className="w-100 mb-3">
      <ReportImage
        onClick={open}
        className="cursor-pointer"
        logo={report.logo}
      />
      <div className="w-100 mt-2 mb-2" style={{ position: "relative" }}>
        <div className="pr-4">
          <Link
            to={url}
            className="rr-t2 rr-dark-grey cursor-pointer nowrap-ellipsis"
          >
            {getProjectTitle(report, t)}
          </Link>
          <div className="rr-t3 rr-grey nowrap-ellipsis overflow-x-hidden">
            {t(texts.modified)} {format(new Date(report.udate), "dd MMM yyyy")}
          </div>
        </div>
        <div
          className="dropdown cursor-pointer px-1"
          style={{ position: "absolute", right: 0, bottom: 0 }}
          onClick={(e) => {
            dropdown.select(report.id, "card");
          }}
        >
          <MoreVertical height={16} width={16} />
          {dropdown.isSelected(report.id, "card") && (
            <ReportDropdown project={report} />
          )}
        </div>
      </div>
    </div>
  );
}

const ReportImage = styled.div<{ logo?: string | null }>`
  border-radius: 6px;
  position: relative;
  width: 100%;
  ${(props) => (props.logo ? `background-image: url('${props.logo}');` : "")}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${theme.lightGrey};
  padding-top: 55%;
  @media (min-width: 768px) {
    padding-top: 66%;
  }
`;
