import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { useLoggedState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import { useLoading } from "../hooks/useLoading";
import * as actions from "../store/actions";
import useI18n from "../hooks/useI18n";
import WorkspaceListing from "../components/ui/WorkspaceListing";
import { Plus } from "react-feather";
import CreateReportDropdown from "../components/ui/CreateReportDropdown";

export function Projects(
  props: RouteComponentProps<{ workspaceId?: string; children: any }>
) {
  const t = useI18n();
  const workspace = useLoggedState(
    (s) => s.workspaces.find((w) => w.id === props.workspaceId) || s.workspace
  );
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [createDropdown, setCreateDropdown] = React.useState(false);
  // Load projects on entering dashboard
  React.useEffect(() => {
    dispatch({ type: "EXIT_PROJECT" });
    if (props.workspaceId) {
      load(dispatch(actions.workspaces.loadWorkspace(props.workspaceId)));
    } else {
      dispatch({ type: "EXIT_WORKSPACE" });
      load(dispatch(actions.projects.loadProjects()));
    }
  }, [dispatch, load, props.workspaceId]);
  return (
    <>
      <FullPage>
        {loading || !workspace ? (
          <></>
        ) : (
          <>
            <div className="m-2 p-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="rr-t1"></div>
                <button
                  className="d-flex align-items-center btn btn-primary btn-sm"
                  // data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateDropdown(true);
                  }}
                >
                  <Plus height="18" className="mr-1" />
                  <div className="d-md-none">
                    {t({ en: <>New report</>, fr: <>Nouveau</> })}
                  </div>
                  <div className="d-none d-md-block">
                    {t({ en: <>New report</>, fr: <>Nouveau rapport</> })}
                  </div>
                </button>
                {createDropdown && (
                  <CreateReportDropdown
                    close={() => setCreateDropdown(false)}
                  />
                )}
              </div>
            </div>
            <WorkspaceListing workspace={workspace} />
          </>
        )}
      </FullPage>
    </>
  );
}
