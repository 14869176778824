import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { Card } from "../components/CardPage";
import { format } from "date-fns";
import { useLoading } from "../hooks/useLoading";
import { api } from "../api";
import { Project, Share } from "../store/types";
import useI18n from "../hooks/useI18n";
import appTexts from "../texts";

export function SharedAccess(
  props: RouteComponentProps<{ projectId: string; shareId: string }>
) {
  const t = useI18n();
  const { projectId, shareId } = props;
  const [loading, load] = useLoading();
  const [error, setError] = React.useState(null);
  const [state, setState] = React.useState({
    project: null as Project | null,
    share: null as Share | null,
  });
  React.useEffect(() => {
    load(
      api
        .GetProjectShare({ projectId: projectId!, shareId: shareId! })
        .then(({ data }) => {
          setState(data);
        })
        .catch((error) => setError(error))
    );
  }, [load, projectId, shareId]);
  const downloadUrl =
    process.env.REACT_APP_API_URL +
    `/api/p/${props.projectId}/${props.shareId}/download.pdf`;
  const viewUrl =
    process.env.REACT_APP_API_URL +
    `/api/p/${props.projectId}/${props.shareId}/report.html`;
  return (
    <>
      <div className="text-center">
        <a
          className="navbar-brand mx-auto mt-5 d-inline-block text-primary"
          href={"https://www.reachreport.io"}
        >
          <img height="42" src="/reachreport_text.svg" alt="ReachReport.io" />
        </a>
      </div>
      <Card>
        <div className="card shadow">
          <div className="card-body p-5">
            <div className="text-center py-4">
              {loading ? (
                <p className="lead">{t(appTexts.loading)}</p>
              ) : error ? (
                <>
                  <h1>{t({ en: "Access denied", fr: "Accès refusé" })}</h1>
                </>
              ) : (
                <>
                  <h1>
                    {t({
                      en: "Your report is ready",
                      fr: "Votre rapport est prêt",
                    })}
                  </h1>
                  <p className="lead"></p>
                  <div>
                    <a className="btn btn-primary btn-lg" href={viewUrl}>
                      {t({ en: "View online", fr: "Voir en ligne" })}
                    </a>
                    <div className="small text-muted mt-2">
                      {t({ en: "Last update:", fr: "Dernière mise à jour :" })}{" "}
                      {format(
                        new Date(state.project?.print_ended || new Date()),
                        "yyyy/MM/dd, hh:mm"
                      )}
                    </div>
                  </div>
                  {state.project?.print_ended != null && (
                    <div className="mt-4">
                      <a href={downloadUrl}>
                        {t({
                          en: "Download the PDF",
                          fr: "Téléchargez le PDF",
                        })}
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
