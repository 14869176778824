import * as React from "react";
import styled from "styled-components";
import { PlanWarning } from "../components/PlanWarning";
import { useAppState } from "../hooks/useAppState";
import theme from "../theme";
import LeftMenu from "./ui/LeftMenu";
import _ from "lodash";

import { Image, Upload, Eye } from "react-feather";
import { Link } from "@reach/router";
import { ProjectIcon } from "./ui/WorkspacesTree";
import Drawers from "../Drawers";
import { DrawerState, useAppDrawer } from "../hooks/useAppDrawer";
import useI18n from "../hooks/useI18n";
import { getProjectTitle, getWorkspaceName } from "../model";

export function PageTitle({
  title,
  children,
}: {
  title: string;
  children?: any;
}) {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h2>{title}</h2>
      {children}
    </div>
  );
}

/** Take all the page. */
export function FullPage({
  children,
  className = "container-fluid",
  noWarning = false,
}: {
  className?: string;
  children: any;
  noWarning?: boolean;
}) {
  const project = useAppState((s) => s.project);
  return (
    <>
      <LeftMenu />
      <TopNav withProject={project !== null}>
        <BreadCrumb />
        <ProjectButtons />
      </TopNav>
      <MainPanel withProject={project !== null}>
        {noWarning || <PlanWarning />}
        <div className={className}>
          <main role="main" style={{ borderTop: "1px solid transparent" }}>
            {children}
          </main>
        </div>
        <Drawers />
      </MainPanel>
    </>
  );
}

const TopNav = styled.div<{ withProject: boolean }>`
  background-color: ${theme.white};
  border-bottom: 2px solid ${theme.lighterGrey};

  position: fixed;
  top: 60px;
  left: 0px;
  right: 0px;
  height: 48px;
  z-index: 1000;
  padding: 0px 15px;

  ${(props) => (props.withProject ? "display: flex;" : "display: none;")}

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: flex;
    margin-left: 252px;
    top: 0px;
    height: 72px;
  }
`;

const MainPanel = styled.div<{ withProject: boolean }>`
  ${(props) => (props.withProject ? "margin-top: 108px;" : "margin-top: 64px;")}
  @media (min-width: 768px) {
    margin-top: 70px;
    margin-left: 252px;
  }
`;

function BreadCrumb() {
  const t = useI18n();
  const { workspace, project } = useAppState((s) =>
    _.pick(s, "workspace", "project")
  );
  return (
    <>
      <div className="d-none d-lg-flex align-items-center justify-content-start">
        {workspace && (
          <>
            {project ? (
              <>
                <div className="rr-t4 rr-mid-grey">
                  <Link
                    to={`/workspaces/${workspace.id}`}
                    className="rr-mid-grey"
                  >
                    {getWorkspaceName(workspace, t)}
                  </Link>
                </div>
                <div className="rr-t2 rr-grey mx-2">/</div>
                <Link
                  to={`/projects/${project.id}`}
                  className="d-flex align-items-center"
                >
                  <ProjectIcon
                    className="d-inline-block"
                    size={24}
                    src={project.logo || ""}
                  />{" "}
                  <div className="d-inline-block  rr-t4 rr-dark-grey no-wrap">
                    {getProjectTitle(project, t)}
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="rr-t4 rr-mid-grey">
                  <Link
                    to={`/workspaces/${workspace.id}`}
                    className="rr-mid-grey"
                  >
                    {getWorkspaceName(workspace, t)}
                  </Link>
                </div>
                <div className="rr-t3 rr-grey mx-2">/</div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

function ProjectButtons() {
  const t = useI18n();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);
  const project = useAppState((s) => s.project);
  return project ? (
    <ProjectButtonsContainer className="flex-grow-1 d-flex justify-content-around justify-content-md-end align-items-center">
      <a
        onClick={(e) => {
          e.preventDefault();
          openDrawer({ project_design: project.id });
        }}
        href="#-"
        className="btn btn-primary btn-sm mr-1 mr-md-3 px-2 px-md-3 w-100 w-md-auto"
        style={{ maxWidth: "150px" }}
      >
        <Image /> {t({ en: "Design", fr: "Design" })}
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          openDrawer({ project_share: project.id });
        }}
        href="#-"
        className="btn btn-secondary btn-sm mr-1 mr-md-3 text-nowrap flex-shrink-1 px-2 px-md-3  w-100 w-md-auto"
        style={{ maxWidth: "150px" }}
      >
        <Upload /> {t({ en: <>Export &amp; Share</>, fr: <>Exporter</> })}
      </a>
      <a
        href={`/projects/${project.id}/preview`}
        target="_blank"
        className="btn btn-secondary btn-sm mr-0 mr-md-2 px-2 px-md-3  w-100 w-md-auto"
        style={{ maxWidth: "150px" }}
        rel="noreferrer"
      >
        <Eye /> {t({ en: "Preview", fr: "Aperçu" })}
      </a>
    </ProjectButtonsContainer>
  ) : (
    <></>
  );
}

const ProjectButtonsContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;
