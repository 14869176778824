import { Link } from "@reach/router";
import { format } from "date-fns";
import React from "react";
import { MoreVertical } from "react-feather";
import { useAppState } from "../../hooks/useAppState";
import { Workspace } from "../../store/types";
import { ReportDropdown, useReportDropdown } from "./ReportDropdown";
import styled from "styled-components";
import theme from "../../theme";
import useI18n from "../../hooks/useI18n";
import texts from "../../texts";
import { getProjectTitle, getWorkspaceName } from "../../model";

export default function WorkspaceListing({
  workspace,
}: {
  workspace: Workspace;
}) {
  const t = useI18n();
  const dropdown = useReportDropdown();
  const projects = useAppState((s) =>
    s.projects.filter((p) => p.workspace_id === workspace.id)
  );
  return (
    <div className="mb-4">
      <div className="rr-t1 rr-dark-grey px-3 py-2 rr-bg-softer-grey">
        {getWorkspaceName(workspace, t)}
      </div>
      <div className="">
        {projects.map((p) => (
          <div key={p.id}>
            <div
              className="p-3 d-flex align-items-center justify-content-between"
              style={{
                borderTop: "1px solid " + theme.lightGrey,
                position: "relative",
              }}
            >
              <Link
                to={`/projects/${p.id}/clips`}
                className="d-flex align-items-center"
              >
                <ProjectIcon src={p.logo || undefined} />
                <span className="rr-t2 rr-dark-grey ">
                  {getProjectTitle(p, t)}
                  <span className="d-block d-md-none rr-t3 rr-grey mr-5 nowrap-ellipsis">
                    {t(texts.modified)}{" "}
                    {format(new Date(p.udate), "dd MMM yyyy")}
                  </span>
                </span>
              </Link>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-none d-md-block rr-t3 rr-grey mr-5 nowrap-ellipsis">
                  {t(texts.modified)} {format(new Date(p.udate), "dd MMM yyyy")}
                </div>
                <div
                  className="align-self-end cursor-pointer px-1"
                  style={{ position: "relative", maxWidth: "32px" }}
                  onClick={() => dropdown.select(p.id)}
                >
                  <MoreVertical height={16} width={16} />
                  {dropdown.isSelected(p.id) && <ReportDropdown project={p} />}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const ProjectIcon = styled.span<{ src?: string }>`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: ${theme.lightGrey};
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
  flex-shrink: 0;
`;
