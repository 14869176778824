import * as React from "react";

type FnArg<T> = Promise<T> | (() => Promise<T>);

export const useLoading = (
  initValue: boolean = false
): [boolean, <T>(p: FnArg<T>) => Promise<T>] => {
  const [loading, setLoading] = React.useState(initValue);
  const load = React.useCallback(<T>(p: FnArg<T>): Promise<T> => {
    setLoading(true);
    const prom = p instanceof Promise ? p : p();
    return prom.finally(() => setLoading(false));
  }, []);
  return [loading, load];
};
