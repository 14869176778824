import * as React from "react";
import ReactDOM from "react-dom";
import useI18n from "../hooks/useI18n";
import appTexts from "../texts";
import cleanUrl from "../utils/clean-url";
import getUrl from "../utils/get-urls-from-text";

/**
 * A modal which presents a way to enter URLs to add to a project.
 */
export function AddPagesModal({
  visible,
  hide,
  addPages,
}: {
  visible: boolean;
  hide: () => void;
  addPages: (urls: string[]) => void;
}) {
  const t = useI18n();
  const handleAddPages = (e: React.FormEvent) => {
    e.preventDefault();
    const str: string = (e.target as HTMLFormElement).urls.value;
    const lines = getUrl(str).map(cleanUrl);
    addPages(lines);
    hide();
  };
  return visible
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div
            className="modal show fade d-block"
            tabIndex={-1}
            role="dialog"
            onClick={hide}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
              role="document"
              style={{ maxWidth: "722px" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content p-3">
                <form onSubmit={handleAddPages}>
                  <div className="modal-body">
                    <h5 className="modal-title rr-h2 mb-2">
                      {t({
                        en: "Add clips to the report",
                        fr: "Ajouter des coupures au projet",
                      })}
                    </h5>
                    <p className="lead rr-t2 rr-mid-grey">
                      {t({
                        en: "Paste links to the coverage you want to include in your report.",
                        fr: "Copiez des liens vers les couvertures à inclure dans votre rapport.",
                      })}
                    </p>
                    <div className="">
                      <label>
                        {t({
                          en: "Add the following URLS:",
                          fr: "Ajouter les URLs suivantes :",
                        })}
                      </label>
                      <textarea
                        className="form-control rr-t3 rr-dark-grey"
                        style={{ height: "5em" }}
                        name="urls"
                        autoFocus
                        placeholder={t({
                          en: "Paste one or more URLs here.",
                          fr: "Coller un ou plusieurs liens ici.\nhttps://...",
                        })}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={hide}
                      >
                        {t(appTexts.cancel)}
                      </button>
                      <button type="submit" className="btn btn-primary">
                        {t({ en: "Load clips", fr: "Charger les coupures" })}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
}
