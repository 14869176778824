import { OpenAPIClientAxios } from "openapi-client-axios";
import { Client as RRClient } from "./client";
const apiDefinition = require("./swagger.json");

export interface ApiError {
  fields: { [field: string]: { message: string } };
  message: string;
  name: string;
  status: 400;
}

export const mkFieldsError = (fields: {
  [path: string]: { message: string };
}): ApiError => {
  return {
    fields,
    message: "Field error",
    name: "FieldError",
    status: 400,
  };
};

export let api: RRClient;

export const init = async () => {
  if (api) {
    return api;
  }
  const rawApi = new OpenAPIClientAxios({
    definition: apiDefinition as any, // process.env.REACT_APP_API_URL + "/api/swagger.json",
    axiosConfigDefaults: {
      baseURL: process.env.REACT_APP_API_URL + "/api/",
      withCredentials: true,
    },
  });
  const client = await rawApi.init<RRClient>();
  api = client;
  return api;
};

export const setAuthToken = (token: string | null) => {
  if (token) {
    api.defaults.headers["authorization"] = "Bearer " + token;
    window.localStorage.setItem("auth_token", token);
  } else {
    delete api.defaults.headers["authorization"];
    window.localStorage.removeItem("auth_token");
  }
};

export const getAuthToken = () => {
  return window.localStorage.getItem("auth_token");
};
