import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { useProject } from "../hooks/useProject";
import useI18n from "../hooks/useI18n";
import appTexts from "../texts";

export function ProjectPreview(props: RouteComponentProps<{ id: string }>) {
  const t = useI18n();
  const [loading] = useProject(props.id || "");
  const previewUrl =
    process.env.REACT_APP_API_URL +
    `/api/projects/${props.id}/html?preview=true`;
  return (
    <>
      {loading ? (
        <p>{t(appTexts.loading)}</p>
      ) : (
        <>
          <iframe
            title={t({ en: "Preview", fr: "Aperçu" })}
            src={previewUrl}
            style={{
              width: "100%",
              // height: "calc(100vh - 108px)",
              // marginTop: "108px",
              height: "100vh",
            }}
          />
        </>
      )}
    </>
  );
}
