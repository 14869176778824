import { Components } from "./client";
import { Workspace } from "./store/types";
import appTexts from "./texts";
import { TFunction } from "./utils/i18n";

export type User = Components.Schemas.User;
export type Page = Components.Schemas.Page;
export type Session = Components.Schemas.Session;
export type Project = Components.Schemas.Project;
export type Feed = Components.Schemas.Feed;
export type FeedItem = Components.Schemas.FeedItem;

export const isPrinting = (p: Project): boolean =>
  p.print_started !== null && p.print_ended === null;

export const isLoading = (p: Page): boolean => p.crawled === false;

export const addVersion = (p: Page, url: string) =>
  url ? url + "?v=" + p.crawl_counter : url;

export const screenshotUrl = (pageId: string, file: string) =>
  file == null || file === ""
    ? ""
    : file.startsWith("http")
    ? file.replace(
        process.env.REACT_APP_API_URL || "xxxxxxx",
        process.env.REACT_APP_DATA_URL || "xxxxxxx"
      )
    : process.env.REACT_APP_DATA_URL + "/screenshots/" + pageId + "/" + file;

export function getPageScreenshots(page: Page): string[] {
  if (((page.data as any)?.screenshots?.length || 0) === 0 && page.screenshot) {
    return [getPageScreenshot(page)];
  }
  const screens =
    (page.data as any)?.screenshots ||
    (page.screenshot ? [getPageScreenshot(page)] : []);
  return screens
    .map(screenshotUrl.bind(null, page.id))
    .filter(Boolean) as string[];
}

export function getPageScreenshot(page: Page): string {
  if (page.screenshot || page.data == null) {
    return screenshotUrl(page.id, page.screenshot!);
  }
  switch (page.kind) {
    case "youtube": {
      const data = page.data as Components.Schemas.YoutubeVideoData;
      return data.image;
    }
    case "web": {
      const data = page.data as Components.Schemas.WebPageMetas;
      return screenshotUrl(page.id, data.screenshots[0]);
    }
    case "twitter": {
      const data = page.data as Components.Schemas.TwitterData;
      return screenshotUrl(page.id, data.screenshots[0]);
    }
    case "linkedin": {
      const data = page.data as Components.Schemas.LinkedinData;
      return screenshotUrl(page.id, data.screenshots[0]);
    }
    case "instagram": {
      const data = page.data as Components.Schemas.InstagramData;
      return screenshotUrl(page.id, data.screenshots[0]);
    }
  }
  return page.screenshot || "";
}

export function getWorkspaceName(w: Workspace, t: TFunction): string {
  return w.name === "Default"
    ? t(appTexts.defaultWorkspace)
    : w.name || t(appTexts.newWorkspace);
}

export function getProjectTitle(p: Project, t: TFunction): string {
  return p.title || t(appTexts.newProject);
}

/**
 * Return main views+users stats for a page.
 * Each kind of page has its way of storing the stats.
 */
export function getPreviewStats(page: Page) {
  switch (page.kind) {
    case "press":
    case "radio":
    case "tv":
    case "custom":
      return {
        views: getStatValue(page.stats, "offline_views"),
        users: getStatValue(page.stats, "offline_users"),
      };
    case "instagram":
      return {
        views: getStatValue(page.stats, "instagram_views"),
        users: getStatValue(page.stats, "instagram_followers"),
      };
    case "twitter":
      return {
        views: getStatValue(page.stats, "twitter_views"),
        users: getStatValue(page.stats, "twitter_followers"),
      };
    case "linkedin":
      return {
        views: getStatValue(page.stats, "linkedin_views"),
        users: getStatValue(page.stats, "linkedin_followers"),
      };
    case "tiktok":
      return {
        views: getStatValue(page.stats, "tiktok_views"),
        users: getStatValue(page.stats, "tiktok_followers"),
      };
    case "youtube":
      return {
        views: getStatValue(page.stats, "youtube_views"),
        users: getStatValue(page.stats, "youtube_followers"),
      };
    case "facebook":
      return {
        views: getStatValue(page.stats, "facebook_views"),
        users: getStatValue(page.stats, "facebook_followers"),
      };
    case "web":
      return {
        views: getStatValue(page.stats, "web_views") || 0,
        users: getStatValue(page.stats, "monthly_users"),
      };
    case "pinterest":
      return {
        views: getStatValue(page.stats, "pinterest_views"),
        users: getStatValue(page.stats, "pinterest_followers"),
      };
  }
  return {
    views: 0,
    users: 0,
  };
}

function getStatValue(stats: Page["stats"], key: string) {
  const s = (stats || ([] as any)).find((s: any) => s.key === key);
  if (!s || s.disabled) return 0;
  return s.userValue || s.rrValue || 0;
}

export function isSocial(k: string): boolean {
  return [
    "youtube",
    "twitter",
    "linkedin",
    "instagram",
    "facebook",
    "tiktok",
    "pinterest",
  ].includes(k);
}
