/**
 * These hooks are currently bound to statics from i18n module since react app only run on the browser.
 * @packageDocumentation
 */
import { Lang, navLang, TFunction, tr } from "../utils/i18n";
import { useAppState } from "./useAppState";

export function useLang(): Lang {
  return useAppState((s) => s.user?.lang as Lang) || navLang;
}

export default function useI18n(): TFunction {
  const locale: Lang = useLang();
  return tr.bind(null, locale || navLang) as TFunction;
}
