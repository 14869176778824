import React, { useEffect } from "react";
import {
  DrawerBody,
  DrawerTitle,
  DrawerSeparator,
  DrawerFooter,
  DrawerSection,
  DrawerSectionBody,
} from ".";
import {
  useDrawerBackdrop,
  useAppDrawer,
  DrawerState,
} from "../../hooks/useAppDrawer";
import useI18n from "../../hooks/useI18n";
import { useLoggedState, useDispatch } from "../../hooks/useAppState";
import { appTexts } from "../../texts";
import { useLoading } from "../../hooks/useLoading";
import { Trash } from "react-feather";
import * as actions from "../../store/actions";
import * as toasts from "../../toasts";
import { isSocial } from "../../model";
import {
  XEmbed,
  FacebookEmbed,
  InstagramEmbed,
  YouTubeEmbed,
  LinkedInEmbed,
  PinterestEmbed,
} from "react-social-media-embed";

/**
 * Drawer to display clip details.
 */
export default function FeedItemDrawer({ id }: { id: string }) {
  useDrawerBackdrop();
  const close = useAppDrawer((state: DrawerState) => state.close);
  const dispatch = useDispatch();
  const t = useI18n();
  const [loading, load] = useLoading();
  const [option, setOption] = React.useState<string>("");
  const item = useLoggedState((state) =>
    state.feedItems.find((i) => i.id === id)
  );
  const projects = useLoggedState((state) => state.projects);
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loading) return;
    if (option === "delete") {
      load(
        dispatch(actions.feeds.deleteItem(id))
          .then(close)
          .then(() => toasts.success("Deleted."))
      );
    } else {
      setOption("delete");
    }
  };
  const handleAddToReport = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    const projectId = (e.target as any).projectId.value;
    load(
      dispatch(actions.feeds.publishItem(id, projectId)).then(() =>
        toasts.success("Added to report.")
      )
    );
  };
  // mark item as read
  useEffect(() => {
    if (item && item.state === "new") {
      dispatch(actions.feeds.updateItem(id, { state: "read" }));
    }
  }, [id, item]);

  return item == null ? (
    <></>
  ) : (
    <>
      <DrawerBody>
        <DrawerTitle
          title={t({
            en: "Feed item details",
            fr: "Détails de l'élément",
          })}
          lead={t({
            en: <>Add the mention to a report or discard it.</>,
          })}
        />
        <DrawerSeparator>
          <div className="rr-t2">{t({ en: "Information" })}</div>
        </DrawerSeparator>
        <div className="my-4">
          <div className="form-group">
            <label className="mb-0">{t({ en: "Link:", fr: "Lien:" })}</label>
            <div className="nowrap-ellipsis">
              <a
                href={item?.link}
                target="_blank"
                rel="noreferrer"
                className="rr-t5"
              >
                {item?.link}
              </a>
            </div>
          </div>
          {isSocial(item.kind) ? (
            <>
              <EmbedSocial kind={item.kind} url={item.link} />
            </>
          ) : (
            <>
              <div className="form-group">
                <label>{t({ en: "Title:", fr: "Titre :" })}</label>
                <div>{item?.title?.replace(/^(…|\.\.\.)/, "")}</div>
              </div>
              {item.title !== item.description && item.description && (
                <div className="form-group">
                  <label>
                    {t({ en: "Description:", fr: "Description :" })}
                  </label>
                  <div>{item?.description?.replace(/^(…|\.\.\.)/, "")}</div>
                </div>
              )}
            </>
          )}
        </div>
      </DrawerBody>
      <DrawerFooter className="pt-0 mt-0">
        <DrawerSeparator className="pt-0 mt-n3">
          <div className="rr-softer-text pt-3 pb-2 px-4">
            <h3 className="rr-t2b mb-2">
              {t({ en: "Add to report...", fr: "Ajouter au rapport..." })}
            </h3>
            {item.state === "published" && (
              <span className="rr-t5 rr-grey">
                👍 This item has been added to a report.
              </span>
            )}
            <form onSubmit={handleAddToReport} className="form-inline d-flex">
              <select
                name="projectId"
                className="form-control custom-select custom-select-sm flex-grow-1"
                disabled={loading}
              >
                {projects.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.title}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={loading}
              >
                {t({
                  en: "Add",
                  fr: "Ajouter",
                })}
              </button>
            </form>
          </div>
        </DrawerSeparator>

        <div className="d-flex justify-content-around mt-3">
          <button
            className="btn btn-danger btn-sm"
            disabled={loading}
            onClick={handleDelete}
          >
            {option === "delete"
              ? t({
                  en: (
                    <>
                      <Trash width="16" /> Confirm
                    </>
                  ),
                })
              : t(appTexts.archive)}
          </button>
        </div>
      </DrawerFooter>
    </>
  );
}

const EmbedSocial = ({ kind, url }: { kind: string; url: string }) => {
  if (kind === "twitter") {
    return <XEmbed url={url} />;
  }
  if (kind === "facebook") {
    return <FacebookEmbed url={url} />;
  }
  if (kind === "instagram") {
    return <InstagramEmbed url={url} />;
  }
  if (kind === "youtube") {
    return <YouTubeEmbed url={url} />;
  }
  if (kind === "linkedin") {
    return <LinkedInEmbed url={url} />;
  }
  if (kind === "pinterest") {
    return <PinterestEmbed url={url} />;
  }
  return <></>;
};
