import * as React from "react";
import { useAppState } from "../hooks/useAppState";
import { Link } from "@reach/router";
import { differenceInDays } from "date-fns";
import useI18n from "../hooks/useI18n";
import Alert from "./ui/Alert";

export function PlanWarning() {
  const t = useI18n();
  const team = useAppState((s) =>
    s.teams.find((t) => t.id === s.session?.team_id)
  );
  if (!team) {
    return null;
  }
  if (team.clips_limit <= team.clips) {
    return (
      <Alert
        danger
        action={{
          link: "/plans",
          label: t({ en: "Upgrade", fr: "Mettre à niveau" }),
        }}
      >
        {t({
          en: (
            <>
              Clip limit reached, <Link to="/plans">upgrade your plan</Link> to
              add more clips.
            </>
          ),
          fr: (
            <>
              Limite atteinte,{" "}
              <Link to="/plans">passez à la formule supérieure</Link> pour
              ajouter de nouveaux liens.
            </>
          ),
        })}
      </Alert>
    );
  }
  if (team.plan === "free") {
    const remaining = team.plan_period_end
      ? differenceInDays(new Date(team.plan_period_end), new Date())
      : -1;
    if (remaining < 0) {
      return (
        <div className="alert alert-danger mb-0">
          <div className="container px-0 px-sm-3">
            {t({
              en: (
                <>
                  Your trial has expired, <Link to="/plans">upgrade now</Link>.
                </>
              ),
              fr: (
                <>
                  Votre période d'essai est terminée,{" "}
                  <Link to="/plans">choisissez une formule</Link>.
                </>
              ),
            })}
          </div>
        </div>
      );
    }
    if (team.plan_status === "payment_failed") {
      return (
        <Alert
          danger
          action={{
            link: "/plans",
            label: t({
              en: "Payment",
              fr: "Paiement",
            }),
          }}
        >
          {t({
            en: (
              <>
                We couldn't process your latest payment.{" "}
                <Link to="/plans">Please review your payment information</Link>{" "}
                to avoid any disruption to the service.
              </>
            ),
            fr: (
              <>
                Nous n'avons pas pu traiter votre dernier paiement.{" "}
                <Link to="/plans">
                  Veuillez vérifier vos informations de paiement
                </Link>{" "}
                pour éviter toute interruption du service.
              </>
            ),
          })}
        </Alert>
      );
    }
    return (
      <div className="alert alert-warning mb-0">
        <div className="container px-0 px-sm-3">
          {t({
            en: (
              <>
                Your trial expires in {remaining || 1} day
                {remaining > 1 ? "s" : ""}, <Link to="/plans">upgrade now</Link>
                .
              </>
            ),
            fr: (
              <>
                Votre période d'essai se termine dans {remaining || 1} jour
                {remaining > 1 ? "s" : ""},{" "}
                <Link to="/plans">choisir une formule</Link>.
              </>
            ),
          })}
        </div>
      </div>
    );
  }
  return null;
}
