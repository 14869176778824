import * as React from "react";
import cls from "classnames";
import styled from "styled-components";
import theme from "../theme";

/**
 * Wrapper for one small card layout pages (ie: Signin, Signup).
 */
export function CardPage({
  topnav,
  coloredBox,
  orange,
  children,
  className,
  large = false,
}: {
  topnav?: any;
  coloredBox?: any;
  orange?: boolean;
  children: any;
  className?: string;
  large?: boolean;
}) {
  return (
    <>
      {topnav && (
        <TopNav className="container-fluid px-lg-5 pt-4">{topnav}</TopNav>
      )}
      <div className="container-fluid bg-white" style={{ minHeight: "100vh" }}>
        <div className="row ">
          <ColoredBox orange={orange}>
            <TextWrapper>
              <div className="text-center d-inline-block">{coloredBox}</div>
            </TextWrapper>
          </ColoredBox>
          <div
            className="col-lg-5 d-flex flex-column justify-content-around align-items-center"
            style={{ backgroundColor: "#ffffff" }}
          >
            <Card className={""} large={large}>
              {children}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export function Card({
  children,
  className,
  large = false,
}: {
  children: any;
  className?: string;
  large?: boolean;
}) {
  return (
    <div className={cls("container", className)}>
      <div
        className={
          large
            ? "mx-auto w-100 w-md-75 w-lg-50 mt-5"
            : "mx-auto w-100 w-md-75 w-lg-75 mt-5"
        }
      >
        <div className="">{children}</div>
      </div>
    </div>
  );
}

export const TopNav = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
`;

export const ColoredBox = styled.div.attrs<{ orange?: boolean }>((props) => ({
  className:
    "col-lg-6 d-flex flex-column justify-content-around align-items-center",
}))<{ orange?: boolean }>`
  background-color: ${(props) =>
    props.orange ? theme.lightGreen : theme.lightestBlue};
  color: ${(props) => (props.orange ? theme.darkBlue : theme.darkBlue)};

  min-height: 275px;
  @media (${theme.media.up.md}) {
    min-height: 275px;
  }
  @media (${theme.media.up.lg}) {
    min-height: 100vh;
  }

  h1 {
    margin-bottom: 0;
    @media (${theme.media.up.md}) {
    }
    @media (${theme.media.up.lg}) {
    }
  }
`;

export const TextWrapper = styled.div`
  margin-top: 60px;

  @media (${theme.media.up.lg}) {
    margin-top: 0px;
    text-align: right;
    width: 100%;
    padding-right: 10%;
  }

  //max-width: 300px;
`;

export const Lead = styled.p.attrs((p) => ({
  ...p,
  className: cls(p.className, "px-5 rr-t2"),
}))`
  text-align: center;
  @media (${theme.media.up.md}) {
  }
  @media (${theme.media.up.lg}) {
  }
`;

export const Label = styled.label`
  color: ${theme.darkGrey};
  font-weight: 300;
`;
