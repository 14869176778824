import React from "react";
import useI18n from "../../hooks/useI18n";
import { Source } from "../../store/types";
import AsyncCreatableSelect from "react-select/async-creatable";
import { api } from "../../api";

const WITH_GLOBALS = true;

async function searchTeam(inputValue: string) {
  return api
    .SearchTeamSources({
      search: inputValue,
      kinds: "" as any,
      // kinds: [], // "print", "audiovisual", "online"],
      offset: 0,
      limit: 100,
    })
    .then((res) => res.data)
    .then((res) =>
      res.map((s) => ({
        value: s,
        label: s.name,
      }))
    );
}

async function searchGlobals(inputValue: string) {
  return api
    .SearchSources({
      search: inputValue,
      kinds: "" as any,
      // kinds: [], // "print", "audiovisual", "online"],
      offset: 0,
      limit: 100,
    })
    .then((res) => res.data)
    .then((res) =>
      res.map((s) => ({
        value: s,
        label: s.name,
      }))
    );
}

async function promiseOptions(inputValue: string) {
  if (WITH_GLOBALS) {
    const [team, globals] = await Promise.all([
      searchTeam(inputValue),
      searchGlobals(inputValue),
    ]);
    return [...team, ...globals];
  }
  return searchTeam(inputValue);
}

export default function SourceInput({
  initialSource,
  setSource,
}: {
  initialSource?: Source | null;
  setSource: (source: { create: string } | Source | null) => void;
}) {
  const t = useI18n();
  return (
    <AsyncCreatableSelect
      isClearable
      cacheOptions
      loadOptions={promiseOptions}
      placeholder={t({ en: "Publication name...", fr: "Publication..." })}
      formatCreateLabel={(s) => t({ en: "Create: ", fr: "Créer : " }) + s}
      defaultValue={
        initialSource
          ? { value: initialSource, label: initialSource.name }
          : null
      }
      onChange={(v: any) => {
        if (!v) {
          setSource(null);
        } else if (v.label == v.value) {
          setSource({ create: v.value });
        } else {
          setSource(v.value);
        }
      }}
    />
  );
}
