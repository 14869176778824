import * as React from "react";
import ReactDOM from "react-dom";
import { useAppState, useDispatch } from "../hooks/useAppState";
import useI18n from "../hooks/useI18n";

export default function FirstClipModal() {
  const dispatch = useDispatch();
  const visible = useAppState((s) => s.firstClipFlag);
  const t = useI18n();
  const handleDispose = (e: any) => {
    e.preventDefault();
    dispatch({ type: "SET_FIRST_CLIP_FLAG", value: false });
  };
  return visible
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div
            className="modal show fade d-block"
            tabIndex={-1}
            role="dialog"
            onClick={handleDispose}
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
              style={{ maxWidth: "522px" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content p-3">
                <form onSubmit={handleDispose}>
                  <div className="modal-body">
                    <h5 className="modal-title rr-h2 mb-2 text-center">
                      {t({
                        en: "Select the best clip",
                        fr: "Choisissez la meilleure capture",
                      })}
                    </h5>
                    <p className="lead rr-t2 rr-mid-grey text-center">
                      {t({
                        en: (
                          <>
                            Clip not perfect?{" "}
                            <strong>Click on its image</strong> to select
                            another.
                          </>
                        ),
                        fr: (
                          <>
                            L'image de votre coupre n'est pas à votre goût ?{" "}
                            <strong>Cliquez sur l'image</strong> pour en choisir
                            une autre.
                          </>
                        ),
                      })}
                    </p>
                    <div className="d-flex justify-content-center mt-3">
                      <button type="submit" className="btn btn-primary">
                        {t({ en: "Got it", fr: "J'ai compris" })}
                      </button>
                      {/* <img
                        src="/ArrowDown.png"
                        alt=""
                        style={{
                          position: "absolute",
                          width: 50,
                          left: "50px",
                          bottom: -50,
                        }}
                      /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
}
