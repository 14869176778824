import classNames from "classnames";
import React from "react";

export default function NothingBlock({
  className = "py-3",
  children,
}: {
  className?: string;
  children: any;
}) {
  return (
    <div
      className={classNames("text-center rr-t2 rr-grey rounded", className)}
      style={{
        border: "1px dashed #9CA3AF",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.07)",
      }}
    >
      {children}
    </div>
  );
}
