import * as React from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { Card } from "../components/CardPage";

export function Privacy(props: RouteComponentProps) {
  return (
    <>
      <p className="lead mt-5 text-center">
        <Link to="/">⟵ Home</Link>
      </p>
      <Card>
        <h1>
          ReachReport.io
          <br />
          Privacy Policy
        </h1>
        <p>Last revised on January 4th, 2023</p>
        <p className="font-weight-bold">
          We will NEVER sell Your information to anyone.
        </p>
        <p>
          ReachReport never sells personal data and carries out all processing
          operations in strict compliance with European privacy laws. You are in
          control of Your personal information at all times.
        </p>
        <p>
          ReachReport values Your Privacy and strives for its services to be
          safe and enjoyable for everyone. This Policy sets out and explains how
          ReachReport collects and processes personal data, the purposes for
          processing and how We protect it.
        </p>

        <h2>Overview</h2>
        <p>
          The database generated from the ReachReport website and services
          belongs to ReachReport.
        </p>
        <p>
          ReachReport will only access data that Users have saved on the
          database following their specific instructions. This data will be used
          exclusively for the purposes specified in the contract and will under
          no circumstances be disclosed to third parties.
        </p>
        <p>
          When a User has registered on ReachReport, their personal data,
          addresses and means of payments are incorporated into ReachReport's
          database so they can benefit from the services and products of
          ReachReport.
        </p>
        <p>
          The aim of this is to provide the User with access to and use of
          ReachReport and its products and to allow ReachReport to draw up
          statistics on those services used and requested by the User, as well
          as to update the service.
        </p>
        <p>
          In accordance with regulations currently in force, We inform Users who
          fill out the said registration form that their personal data will be
          held on a file.
        </p>
        <p>
          At any time, Users may exercise their rights of access, rectification,
          cancellation and opposition to the processing of personal data by
          sending an email to legal AT ReachReport.io.
        </p>

        <h2>Information We Collect</h2>
        <p>
          <em>Personal identification data:</em> full name, address, phone
          number, and email address.
        </p>
        <p>
          <em>Financial data:</em> tax ID, VAT number, bank account number,
          invoices, and credit notes.
        </p>
        <p>
          <em>Device-specific data:</em> IP address (to comply with EU VAT rules
          for e-services).
        </p>
        <p>
          <em>Log data:</em> referring URL and domain, pages visited, geographic
          location, preferred language used to display the webpage, and date and
          time when website pages were accessed.
        </p>
        <p>
          <em>Cookies</em>
        </p>
        <p>
          A cookie is a file that is downloaded onto Your device when You access
          certain webpages. Cookies allow the webpage, among other things, to
          store and retrieve information about the number of times You visit it,
          the browser habits of a user or computer and, depending on the
          information that it contains and the way in which the computer is
          used, cookies can be used for user recognition.
        </p>
        <p>
          Bear in mind that in order to be able to use certain services and
          functionalities that We offer on the ReachReport platform, You will
          need to have cookies enabled on Your browser.
        </p>
        <p>
          Most browsers allow You to choose whether to accept cookies or not. If
          You do not wish to have cookies placed on Your device, please set Your
          browser preferences to reject all cookies, before accessing
          ReachReport.{" "}
        </p>
        <p>
          To find out more about cookies, including how to see what cookies have
          been set and how to manage and delete them, visit{" "}
          <a
            href="https://allaboutcookies.org"
            target="_blank"
            rel="noreferrer"
          >
            allaboutcookies.org
          </a>
          .
        </p>

        <h2>Security and Data Center Location</h2>
        <p>
          ReachReport's primary data and servers are hosted at Scaleway's data
          center in France (EU).
        </p>
        <p>
          ReachReport has implemented various measures to ensure that Your
          information is adequately protected against unauthorised access, use,
          disclosure and destruction. Please keep in mind that risk can never be
          eliminated but can be significantly mitigated and reduced. ReachReport
          shall not be held liable by any Third Party, including You, in any
          event of unauthorized access, use and/or disclosure of information
          provided that such is not due to Gross Negligence, willful misconduct,
          fraud or bad faith by ReachReport.
        </p>
        <p>Security measures adopted by ReachReport include:</p>
        <ul>
          <li>
            access to the information stored within ReachReport's servers is
            restricted to a limited number of ReachReport employees and Third
            Parties who can access the information only in specific and limited
            circumstances and are bound by confidentiality.
          </li>
          <li>
            ReachReport's servers are protected by (1) firewalls establishing a
            barrier between Our trusted, secure internal network and the
            Internet and (2) IP restrictions, limiting access to whitelisted IP
            addresses.
          </li>
          <li>
            each User may only access information pertaining to its ReachReport
            account.
          </li>
          <li>
            we use HTTPS for ReachReport's Services providing secure transfer of
            data to prevent wiretapping and man-in-the-middle attacks.
          </li>
        </ul>
        <p>
          ReachReport reviews its information collection and processing
          practices periodically and will review and amend this Privacy Policy
          accordingly.
        </p>
        <h2>Access and Disclosure</h2>
        <p>
          ReachReport does not rent or sell Your information, but we do disclose
          Your information to a limited set of trusted Third Parties in the
          situations explained below, for which You, by using Our Services,
          hereby explicitly consent.
        </p>
        <ul>
          <li>
            we will disclose Your personal information where We are bound to do
            so, at law or via a court order as well as to meet any legal or
            regulatory requirement or obligations. We will use all reasonable
            efforts to ensure that those requirements or obligations are in
            accordance with Applicable Law.
          </li>
          <li>
            we reserve the right to disclose Your information to any Third Party
            if We have reasonable information to believe that the disclosure is
            necessary for the purpose of an investigation and/or for the
            enforcement of any breaches of the Terms of Service (if applicable),
            to detect, prevent or otherwise address fraud, security, technical
            issues or other irregularities or illegalities, protect the rights
            and interests as well as the property of ReachReport.
          </li>
          <li>
            we may also share aggregated anonymized, non-personal information
            with the public or with any Third Party for researching or
            publishing industry trends related to ReachReport's Services.
          </li>
        </ul>
        <p>
          ReachReport also works with Third Party providers and business
          partners that provide functions that allow us to provide a better
          service.. We need to disclose user data to them from time to time so
          that the services can be performed.
        </p>
        <p>
          We only share information with Third Parties that is required for the
          service they are offering and contractually bind these providers to
          keep any information We share with them as confidential and to be used
          only for particular purposes. For example, amongst others, We have
          providers that process our transactions and manage Our marketing
          communications. We also make use of the YouTube API, which binds You
          to the Google Privacy Policy that you can consult{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://policies.google.com/privacy"
          >
            here
          </a>
          . By using ReachReport, You explicitly consent to, and authorize us to
          sub-contract in this manner.
        </p>
        <p>
          We may revise this Privacy Policy from time to time. The most current
          version of the policy, found at{" "}
          <a href="https://app.reachreport.io/privacy">
            https://app.reachreport.io/privacy
          </a>{" "}
          will govern Our use of Your information collected and processed from
          ReachReport.
        </p>
        <p>
          All changes to this Privacy Policy automatically take effect on
          whichever comes first: Your use of the Site and/or Services, or 30
          calendar days after they are initially posted on the Site. Your use of
          the Site and/or Services following the effective date of any
          modifications to this Agreement will constitute Your acceptance of the
          Agreement, as modified.
        </p>
        <h2>Governing Law and Dispute Resolution</h2>
        <p>
          This Privacy Policy forms an integral part of Our Terms of Service.
          The Governing Law and Dispute Resolution mechanism found in Our Terms
          of Service shall also apply to Our Privacy Policy.
        </p>
        <h2>Further Information</h2>
        <p>
          If you have any questions about our Privacy Policy or our privacy
          practices, please contact Us.
        </p>
        <p>
          If You are based in Europe and need standard contractual clauses
          signed to be able to use ReachReport, You can contact us at legal AT
          ReachReport.io
        </p>
      </Card>
    </>
  );
}
