export const appTexts = {
  email_placeholder: { en: "john@clark.com", fr: "louise@leroux.fr" },
  loading: { en: "Loading...", fr: "Chargement..." },
  miscActions: { en: "Misc actions:", fr: "Autres actions :" },
  cancel: { en: "Cancel", fr: "Annuler" },
  delete: { en: "Delete", fr: "Supprimer" },
  deleteAll: { en: "Delete All", fr: "Tout Supprimer" },
  close: { en: "Close", fr: "Fermer" },
  create: { en: "Create", fr: "Créer" },
  save: { en: "Save", fr: "Enregistrer" },
  saved: { en: "Saved", fr: "Enregistré." },
  retry: { en: "Retry", fr: "Réessayer" },
  archive: { en: "Archive", fr: "Archiver" },
  archiveSelection: { en: "Archive Selection", fr: "Archiver la sélection" },
  archiveRead: { en: "Archive Read", fr: "Archiver Lus" },
  archiveAll: { en: "Archive All", fr: "Archiver Tout" },
  confirmArchive: { en: "Confirm Archive", fr: "Confirmer l'archivage" },
  confirmDelete: { en: "Confirm delete", fr: "Confirmer la suppression" },
  rename: { en: "Rename", fr: "Renommer" },
  newReport: { en: "New report", fr: "Nouveau rapport" },
  team: { en: "Team", fr: "Équipe" },
  subscription: { en: "Subscription", fr: "Abonnement" },
  accountSettings: { en: "Account settings", fr: "Préférences" },
  confirm: { en: "Confirm", fr: "Confirmer" },
  modified: { en: "Modified", fr: "Modifié le " },

  stats: {
    groups: {
      General: { en: "General", fr: "Général" },
      Offline: { en: "Offline", fr: "Hors ligne" },
      "Social Shares": { en: "Social Shares", fr: "Partages sociaux" },
    },
    labels: {
      Views: { en: "Views", fr: "Vues" },
      Readership: { en: "Readership", fr: "Audience" },
      "AD Value": { en: "AD Value", fr: "Equivalent Pub" },
      "PR Value": { en: "PR Value", fr: "Valeur PR" },
    },
  },
  defaultWorkspace: { en: "Default", fr: "Sans nom", es: "Default" },
  defaultProject: { en: "Untitled", fr: "Sans nom", es: "Sin título" },
  newWorkspace: { en: "Untitled", fr: "Sans nom", es: "Sin título" },
  newProject: {
    en: "New report",
    fr: "Nouveau rapport",
    es: "Nuevo proyecto",
  },
  ascending: {
    en: "ascending",
    fr: "croissant",
    es: "ascendente",
  },
  descending: {
    en: "descending",
    fr: "décroissant",
    es: "descendente",
  },
};

export default appTexts;
