import React from "react";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n, { useLang } from "../../hooks/useI18n";
import { State } from "../../store/types";
import { Lang } from "../../utils/i18n";
import * as actions from "../../store/actions";
import { DrawerBody, DrawerSection, DrawerTitle } from ".";
import * as toast from "../../toasts";
import Select from "react-select";
import { DrawerState, useAppDrawer } from "../../hooks/useAppDrawer";

export default function AccountSettingsDrawer() {
  const user = useAppState((s) => s.user);
  const t = useI18n();
  const lang = useLang();
  const dispatch = useDispatch();
  const team = useAppState(State.getTeam);
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);
  const changeLang = (l: Lang) => {
    dispatch(actions.setLang(l)).then(toast.saved);
  };
  return user == null ? (
    <></>
  ) : (
    <DrawerBody>
      <DrawerTitle
        title={t({ en: "Account Settings", fr: "Préférences de compte" })}
        lead={t({
          en: <>Edit your information and preferences</>,
          fr: <>Modifiez vos informations et préférences</>,
        })}
      />

      <DrawerSection title="Information">
        <div>
          <label>{t({ en: "Email address:", fr: "Adresse email :" })}</label>
          <span className="rr-t4 rr-mid-grey pl-1">{user.email}</span>
        </div>
        <div>
          <label>{t({ en: "Password:", fr: "Mot de passe :" })}</label>
          <span className="rr-t4 rr-mid-grey pl-1">*******</span>
        </div>
        {/* <div>
            <button className="btn btn-primary btn-sm">Edit</button>
          </div> */}
      </DrawerSection>

      <DrawerSection title={t({ en: "Language", fr: "Langue" })}>
        <label htmlFor="languageSelect" className="d-block">
          {t({ en: "Select language:", fr: "Choisissez la langue :" })}
        </label>
        <select
          id="languageSelect"
          className="form-control custom-select"
          value={lang}
          onChange={(e) => changeLang(e.target.value as Lang)}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </DrawerSection>

      <DrawerSection title={t({ en: "Time zone", fr: "Fuseau Horaire" })}>
        <label htmlFor="timezone" className="d-block">
          {t({
            en: "Select time zone for reports:",
            fr: "Choisissez le fuseau horaire des rapports :",
          })}
        </label>
        <div style={{ position: "relative" }}>
          <Select
            menu
            styles={{
              menu: (base: any) => ({ ...base, zIndex: 20000 }),
              menuPortal: (base: any) => ({ ...base, zIndex: 20000 }),
            }}
            onChange={(e) => {
              if (e?.value) {
                dispatch(actions.teams.setTimezone(e.value)).then(toast.saved);
              }
            }}
            defaultValue={{
              label: team?.timezone || "UTC",
              value: team?.timezone || "UTC",
            }}
            options={["UTC"]
              .concat((Intl as any).supportedValuesOf("timeZone"))
              .map((v) => ({ label: v, value: v }))}
          />
        </div>
      </DrawerSection>

      {/* Activate CFC  */}
      <DrawerSection title={t({ en: "Options", fr: "Options" })}>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="switchCfcEnabled"
            checked={team?.cfc_enabled}
            onChange={(e) => {
              dispatch(actions.teams.setCfcEnabled(e.target.checked)).then(
                toast.saved
              );
            }}
          />
          <label
            className="custom-control-label rr-t3 rr-mid-grey"
            htmlFor="switchCfcEnabled"
          >
            {t({
              en: "Activate French CFC module (experimental)",
              fr: "Activer le module CFC (expérimental)",
            })}
          </label>
        </div>
        {team?.cfc_enabled && (
          <p className="text-muted small alert-info mt-2 p-2 ">
            {t({
              fr: (
                <>
                  Accédez à vos données CFC depuis le menu{" "}
                  <a
                    href="/cfc"
                    onClick={(e) => {
                      e.preventDefault();
                      openDrawer("cfc");
                    }}
                    className="text-underline"
                  >
                    ou en suivant ce lien
                  </a>
                  .
                </>
              ),
              en: (
                <>
                  Access your CFC data using the menu{" "}
                  <a
                    href="/cfc"
                    onClick={(e) => {
                      e.preventDefault();
                      openDrawer("cfc");
                    }}
                    className="text-underline"
                  >
                    or following this link
                  </a>
                  .
                </>
              ),
            })}
          </p>
        )}
      </DrawerSection>
    </DrawerBody>
  );
}
