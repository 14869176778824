import React from "react";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import { useLoading } from "../../hooks/useLoading";
import { Membership } from "../../store/types";
import * as actions from "../../store/actions";
import { DrawerBody, DrawerSection, DrawerTitle } from ".";
import * as toasts from "../../toasts";
import { MemberDropdown, useMemberDropdown } from "./teams/MemberDropdown";
import { MoreVertical } from "react-feather";
import { toast } from "react-toastify";

export const texts = {
  roles: {
    owner: { en: "owner", fr: "propriétaire" },
    admin: { en: "admin", fr: "administrateur" },
    editor: { en: "editor", fr: "éditeur" },
    reader: { en: "reader", fr: "lecteur" },
  },
  cancel: { en: "Cancel", fr: "Annuler" },
  save: { en: "Save", fr: "Enregistrer" },
  send: { en: "Send", fr: "Envoyer" },
  team: { en: "Team", fr: "Équipe" },
  delete: { en: "Delete", fr: "Supprimer" },
  confirm: { en: "Confirm", fr: "Confirmer" },
};

/**
 * Teams drawer.
 */
export default function TeamsDrawer() {
  const t = useI18n();
  const canManage = useAppState(
    (s) => s.session && ["admin", "owner"].includes(s.session.role)
  );
  return (
    <DrawerBody>
      <DrawerTitle
        title={t(texts.team)}
        lead={
          canManage ? (
            t({
              en: (
                <>
                  Share your account with colleagues so they can collaborate and
                  create reports.
                </>
              ),
              fr: (
                <>
                  Partagez votre compte avec des collègues afin qu'ils puissent
                  collaborer et créer des rapports.
                </>
              ),
            })
          ) : (
            <></>
          )
        }
      />

      <DrawerSection title={t({ en: "Send invitation", fr: "Inviter" })}>
        <p>
          {t({
            en: (
              <>
                Send an invite to access this account to the following email
                address:
              </>
            ),
            fr: <>Invitez le mail suivant dans votre équipe :</>,
          })}
        </p>
        <InvitationForm />
      </DrawerSection>

      <DrawerSection
        className="pb-0"
        title={t({ en: <>Team Members</>, fr: <>Membres de l'équipe</> })}
      >
        <p>
          {t({
            en: <>View or edit your team members.</>,
            fr: <>Gérez votre équipe</>,
          })}
        </p>
        <MembersTable />
      </DrawerSection>

      {/* <div className="mt-2">
          <div className="alert alert-info rr-t3 mt-3 p-2 px-3 rounded">
            Pending invitation foo@bar.com.
          </div>
        </div> */}
    </DrawerBody>
  );
}

/**
 * Invitation form display.
 */
function InvitationForm() {
  const t = useI18n();
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState<Membership["role"]>("editor");
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || loading) {
      return;
    }
    load(dispatch(actions.memberships.create({ email, role })))
      .then(() => {
        toast.success(
          t({
            en: `Invitation sent to ${email}.`,
            fr: `Invitation envoyée à ${email}`,
          }),
          {
            autoClose: 2000,
            position: "top-right",
          }
        );
        setEmail("");
        setRole("editor");
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          toasts.error(
            t({
              en: "This email is already invited.",
              fr: "Cet email est déjà invité.",
            })
          );
        }
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="email@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          disabled={loading}
        />
      </div>
      <div className="mb-3">
        <label>{t({ en: "Role", fr: "Rôle" })}</label>
        <select
          value={role}
          className="form-control custom-select"
          onChange={(e) => setRole(e.target.value as any)}
          disabled={loading}
        >
          <option value="admin">{t(texts.roles.admin)}</option>
          <option value="editor">{t(texts.roles.editor)}</option>
        </select>
      </div>
      <div>
        <button className="btn btn-primary px-3" disabled={loading}>
          {t(texts.send)}
        </button>
      </div>
    </form>
  );
}

/**
 * Members management.
 */
function MembersTable() {
  const t = useI18n();
  const [, load] = useLoading();
  const dispatch = useDispatch();
  const currentUser = useAppState((s) => s.user);
  const dropdown = useMemberDropdown();
  const memberships = useAppState((s) => s.memberships);
  React.useEffect(() => {
    load(dispatch(actions.memberships.list()));
  }, [dispatch, load]);
  return (
    <table className="table border-less mb-0">
      <thead className="border-0">
        <tr>
          <th className="rr-t3 rr-dark-grey px-0 border-0">Email</th>
          <th className="rr-t3 rr-dark-grey px-0 border-0">
            {t({ en: "Role", fr: "Rôle" })}
          </th>
          <th className="px-0 border-0"></th>
        </tr>
      </thead>
      <tbody className="border-0">
        {memberships.map((m) => (
          <tr key={m.user_id} className="border-top">
            <td className="rr-t3b rr-dark-grey px-0 valign-middle">
              {m.user_id === currentUser?.id
                ? t({ en: "You", fr: "Vous" })
                : m.email}
            </td>
            <td className="rr-t3 rr-mid-grey px-0 valign-middle">
              {m.user_id !== currentUser?.id ? (
                <span style={{ textTransform: "capitalize" }}>
                  {t(texts.roles[m.role])}
                </span>
              ) : (
                t(texts.roles[m.role])
              )}
            </td>
            <td className="text-right px-0 valign-middle">
              <div
                className="cursor-pointer px-1"
                //data-toggle="dropdown"
                aria-expanded="false"
                style={{ position: "relative" }}
                onClick={() => dropdown.select(m.user_id)}
              >
                {m.user_id !== currentUser?.id && (
                  <>
                    <MoreVertical height={16} width={16} />
                    {dropdown.isSelected(m.user_id) && (
                      <MemberDropdown member={m} />
                    )}
                  </>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
